const keys = {
  "nb-NO": {
    og: "og",
    areal: "{{val, number}} m²",
    matrikkelnummer_forklaring:
      "Matrikkelnummeret er et unikt nummer som er bygd opp slik: kommunenummer - gårdsnummer / bruksnummer / festenummer / seksjonsnummer. Om festenummer og seksjonsnummer er med i matrikkelnummeret avhenger av hvordan type eiendom du har.",
    matrikkelnummer_forklaring_arialabel: "Hva er et matrikkelnummer?",
    matrikkelenhetstype_forklaring_begrep:
      "Alle eiendommer må være registrert med en matrikkelenhetstype som beskriver eiendommens fysiske eller formelle egenskaper, slik som utstrekning eller måten eiendommen kan eies på. Les mer under “Hjelp og støtte”.",
    matrikkelenhetstype_forklaring_begrep_arialabel: "Hva betyr type eiendom?",
    mine_eiendommer: "Mine eiendommer",
    kommune: "{{navn}} kommune",
    matrikkelnummer: "Matrikkelnummer",
    type_eiendom: "Type eiendom",
    eierseksjon: "Eierseksjon",
    laster_inn_forklaring: "Laster inn forklaring...",
    vis_forklaring: "Vis forklaring",
    hva_betyr_dette: "Hva betyr dette?",
    hva_betyr_type_arialabel: "Hva betyr {{type}}?",
    ikke_beregnet: "Ikke beregnet",
    ikke_registrert: "Ikke registrert",
    med_matrikkelnummer:
      "med matrikkelnummer {{matrikkelnummer, Matrikkelnummer(matrikkelnummer)}}",
    artikkel_slutt: "Artikkel slutt",
    ja: "Ja",
    nei: "Nei",
    se_alle: "Se alle",
    avbryt: "Avbryt",
  },
  "nn-NO": {
    og: "og",
    areal: "{{val, number}} m²",
    matrikkelnummer_forklaring:
      "Matrikkelnummeret er et unikt nummer som er bygd opp slik: kommunenummer - gårdsnummer / bruksnummer / festenummer / seksjonsnummer. Om festenummer og seksjonsnummer er med i matrikkelnummeret avhenger av hvordan type eiendom du har.",
    matrikkelnummer_forklaring_arialabel: "Hva er et matrikkelnummer?",
    matrikkelenhetstype_forklaring_begrep: "Hva betyr type eiendom?",
    matrikkelenhetstype_forklaring_begrep_arialabel: "Hva betyr type eiendom?",
    mine_eiendommer: "Mine eigedommar",
    kommune: "kommune",
    matrikkelnummer: "Matrikkelnummer",
    type_eiendom: "Type eigedom",
    eierseksjon: "Eierseksjon",
    laster_inn_forklaring: "Laster inn forklaring...",
    vis_forklaring: "Vis forklaring",
    hva_betyr_dette: "Kva betyr dette?",
    hva_betyr_type_arialabel: "Kva betyr {{type}}?",
    ikke_beregnet: "",
    ikke_registrert: "",
    med_matrikkelnummer:
      "med matrikkelnummer {{matrikkelnummer, Matrikkelnummer(matrikkelnummer)}}",
    artikkel_slutt: "Artikkel slutt",
    ja: "Ja",
    nei: "Nei",
    se_alle: "Sjå alle",
    avbryt: "Avbryt",
  },
}

type NøkkelbegreperKey = keyof (typeof keys)["nb-NO"]

type Nøkkelbegreper = {
  [K in keyof typeof keys]: Record<NøkkelbegreperKey, string>
}

export const nøkkelbegreper: Nøkkelbegreper = {
  "nb-NO": keys["nb-NO"],
  "nn-NO": keys["nn-NO"],
}
