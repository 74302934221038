import { Flex, Icon } from "@kvib/react"
import { useTranslation } from "react-i18next"
import { formaterMedKommaOgOg } from "../../../../../utils/formateringUtils"
import ErrorHandler from "../../../../core/error-handling/ErrorHandler"
import { Brødtekst } from "../../../../core/text/components/Brødtekst"
import { useKodelister } from "../../../kodelister/useKodelister"
import { hentUtfraKodeliste } from "../../../kodelister/utils"
import OverskriftForEiendomsdetaljer from "../../components/OverskriftForEiendomsdetaljer"
import { Bygning, Bygningsegenskaper } from "../types/Bygning"
import { avløpMapping } from "../utils/avløpsUtil"
import { energikildeMapping } from "../utils/energikilderUtil"
import { vannforsyningkodeMapping } from "../utils/vannforsyningUtil"

const EgenskaperVedBygning = ({ bygning }: { bygning: Bygning }) => {
  const { data: kodelister, error, isError, isLoading, isSuccess } = useKodelister()
  const { t } = useTranslation(["egenskaperVedBygning", "nøkkelbegreper", "bygningskort"])

  if (isLoading || !isSuccess) return null
  if (isError) return <ErrorHandler apiError={error} />

  const bygningsegenskaper: Bygningsegenskaper[] = [
    {
      title: t("bruksareal_tittel", { ns: "egenskaperVedBygning" }),
      ikon: "square_foot",
      evaluate: (bygning: Bygning) => {
        if (bygning.bruksarealTotalt === 0.0 || !bygning.bruksarealTotalt) {
          return t("ikke_registrert", { ns: "nøkkelbegreper" })
        } else {
          const bruksareal = t("areal", { ns: "nøkkelbegreper", val: bygning.bruksarealTotalt })

          return bruksareal
        }
      },
    },
    {
      title: t("antall_etasjer_tittel", { ns: "egenskaperVedBygning" }),
      ikon: "floor",
      evaluate: (bygning: Bygning) => {
        if (bygning.etasjer.length === 0) {
          return t("ikke_registrert", { ns: "nøkkelbegreper" })
        }

        // Tell antall etasjer per etasjeplankode
        const etasjeplankodeMap: { [key: string]: number } = {}
        bygning.etasjer.forEach(etasje => {
          const etasjeFraKodeliste = hentUtfraKodeliste(
            etasje.etasjeplankode,
            kodelister.etasjeKodeliste,
          ).toLowerCase()

          const etasjetekst = etasjeFraKodeliste === "loft" ? "loftsetasje" : etasjeFraKodeliste

          etasjeplankodeMap[etasjetekst] = (etasjeplankodeMap[etasjetekst] || 0) + 1
        })

        const etasjeDescriptions = Object.entries(etasjeplankodeMap).map(([description, count]) => {
          return `${count} ${description}${count > 1 ? "r" : ""}`
        })

        // Sorter så 'hovedetasje' kommer først
        etasjeDescriptions.sort((a, b) => {
          if (a.includes("hovedetasje")) return -1
          if (b.includes("hovedetasje")) return 1
          return 0
        })

        if (etasjeDescriptions.length === 1) {
          return etasjeDescriptions[0]
        }

        const lastDescription = etasjeDescriptions.pop()
        const antallEtasjer = t("antall_etasjer", {
          count: bygning.etasjer.length,
          ns: "bygningskort",
        })

        const og = t("og", { ns: "nøkkelbegreper" })

        return `${antallEtasjer}: ${etasjeDescriptions.join(", ")} ${og} ${lastDescription}`
      },
    },
    {
      title: t("vannforsyning_tittel", { ns: "egenskaperVedBygning" }),
      ikon: "water_drop",
      evaluate: (bygning: Bygning) => vannforsyningkodeMapping[bygning.vannforsyning],
    },
    {
      title: t("avløp_tittel", { ns: "egenskaperVedBygning" }),
      ikon: "valve",
      evaluate: (bygning: Bygning) => avløpMapping[bygning.avløp],
    },
    {
      title: t("energikilder_tittel", { ns: "egenskaperVedBygning" }),
      ikon: "bolt",
      evaluate: (bygning: Bygning) =>
        bygning.energikilder.length > 0
          ? bygning.energikilder.map(kilde => energikildeMapping[kilde] || kilde)
          : t("ikke_registrert", { ns: "nøkkelbegreper" }),
    },
    {
      title: t("oppvarmingstyper_tittel", { ns: "egenskaperVedBygning" }),
      ikon: "heat",
      evaluate: (bygning: Bygning) =>
        bygning.oppvarming.length > 0
          ? bygning.oppvarming.map(type =>
              hentUtfraKodeliste(type, kodelister.oppvarmingstypeKodeliste),
            )
          : t("ikke_registrert", { ns: "nøkkelbegreper" }),
    },
  ]

  const EgenskaperFelter = ({ attributt }: { attributt: Bygningsegenskaper }) => {
    const verdier = attributt.evaluate(bygning)
    const formattedVerdier = Array.isArray(verdier) ? formaterMedKommaOgOg(verdier) : verdier
    const iconSize = 28

    const iconWrapper = `${iconSize + 6}px`

    return (
      <Flex gap={4} mt="18px" alignItems="center">
        <Flex
          bg="green.100"
          borderRadius="50%"
          minW={iconWrapper}
          minH={iconWrapper}
          justifyContent="center"
          alignItems="center"
        >
          <Icon size={iconSize} icon={attributt.ikon} color="green" ariaIsHidden />
        </Flex>
        <Flex flexDir="column">
          <OverskriftForEiendomsdetaljer>{attributt.title}</OverskriftForEiendomsdetaljer>
          <Brødtekst fontWeight="bold">{formattedVerdier}</Brødtekst>
        </Flex>
      </Flex>
    )
  }

  return (
    <>
      {bygningsegenskaper.map((attributt, index) => (
        <EgenskaperFelter key={index} attributt={attributt} />
      ))}
    </>
  )
}

export default EgenskaperVedBygning
