import { Avløpstype, Energikildetype, Oppvarmingstype, Vannforsyningstype } from "./Enums"

export type EgenregistreringBygning = {
  bygningId: string
  bygningsnummer: string
  bruksenheter: EgenregistreringBruksenhet[]
}

export type EgenregistreringBruksenhet = {
  bruksenhetId: number
  etasjer: BruksenhetEtasje[]
  byggeår?: Byggeår
  bruksareal?: Bruksareal
  avløp?: Avløp
  vannforsyning?: Vannforsyning
  energikilder?: Energikilde[]
  oppvarming?: Oppvarming[]
}

type BruksarealRegistrering = {
  totaltBruksareal?: number
  etasjeRegistrertBruksareal?: EtasjeRegistrering[]
}

export type EtasjeRegistrering = {
  etasjebetegnelse: string
  bruksareal: number
}

type BruksenhetEtasje = {
  etasjebetegnelse: string
  bruksareal: BruksarealEtasje
}

type BruksarealEtasje = { data: number; metadata: Metadata }

type Registrering<T> = {
  data: T
  metadata: Metadata
}

type Byggeår = Registrering<number>
type Bruksareal = Registrering<BruksarealRegistrering>
type Avløp = Registrering<keyof typeof Avløpstype>
type Vannforsyning = Registrering<keyof typeof Vannforsyningstype>
type Energikilde = Registrering<keyof typeof Energikildetype>
type Oppvarming = Registrering<keyof typeof Oppvarmingstype>

type Metadata = {
  registreringstidspunkt: string
  registrertAvDeg: boolean
  kildemateriale?: Kildekode
}

export enum Kildekode {
  Selvrapportert = "Selvrapportert",
  Salgsoppgave = "Salgsoppgave",
  Byggesaksdokumenter = "Byggesaksdokumenter",
  Plantegninger = "Plantegninger",
  AnnenDokumentasjon = "AnnenDokumentasjon",
}

export type Kildemateriale = {
  kode: Kildekode
  presentasjonsnavn: string
  beskrivelse: string
}
