import i18n from "i18next"
import { formaterMedKommaOgOgKunStoreBokstaver } from "../../../../utils/formateringUtils"
import { Adresse, Matrikkeladresse, Matrikkelnummer, Vegadresse } from "../types/Eiendom"

type GrupperteVegnavn = { [key: string]: Vegadresse[] }

/**
 * Setter sammen en adresse-string basert på om den første adressen er en veg- eller matrikkeladresse,
 * om matrikkelenheten er en seksjon, og om det er flere adresser knyttet til matrikkelenheten
 */
export function formaterAdresseForEiendom(
  adresser: Adresse | undefined,
  matrikkelnummer: Matrikkelnummer | undefined,
  matrikkelenhetType: string,
): string {
  if (!adresser || (!adresser.vegadresser?.length && !adresser.matrikkeladresser?.length)) {
    return i18n.t("eiendom_uten_adresse", { ns: "eiendomskort" })
  }

  const adressedeler: string[] = []

  if (adresser.vegadresser?.length) {
    const grupperteVegnavn = grupperVegnavn(adresser.vegadresser)
    if (Object.keys(grupperteVegnavn).length > 3) {
      return i18n.t("eiendom_med_antall_adresser", {
        ns: "eiendomskort",
        count: adresser.vegadresser.length,
      })
    }
    const formattedVegadresse = formaterFlereVegadresser(grupperteVegnavn, matrikkelenhetType)

    adressedeler.push(`${formattedVegadresse}`)
  } else if (adresser.matrikkeladresser) {
    adresser.matrikkeladresser.forEach(matrikkeladresse => {
      const formattedMatrikkeladresse = formaterMatrikkeladresse(matrikkeladresse, matrikkelnummer)
      adressedeler.push(formattedMatrikkeladresse)
    })
  }

  return adressedeler.join(", ")
}

export function formaterFlereVegadresserForBygning(vegadresser: Vegadresse[]): string {
  function formaterVegadresse(vegadresse: Vegadresse): string {
    return `${vegadresse.husnummer}${vegadresse.bokstav ?? ""}`
  }

  const unikeVegnavn: { [vegnavn: string]: string[] } = vegadresser.reduce(
    (acc, vegadresse) => {
      if (!acc[vegadresse.vegnavn]) {
        acc[vegadresse.vegnavn] = []
      }
      acc[vegadresse.vegnavn].push(formaterVegadresse(vegadresse))
      return acc
    },
    {} as { [vegnavn: string]: string[] },
  )

  const formaterteUnikeVegnavn = Object.entries(unikeVegnavn).map(([vegnavn, addresses]) => {
    return `${vegnavn} ${formaterMedKommaOgOgKunStoreBokstaver(addresses)}`
  })

  return formaterMedKommaOgOgKunStoreBokstaver(formaterteUnikeVegnavn)
}

function utledBruksenhetsnummerForAdresse(
  bruksenhetsnumre: string[],
  matrikkelenhetstype: string,
): string {
  if (matrikkelenhetstype !== "Eierseksjon") return ""

  const formaterBruksenhetsnumre = (numre: string[]): string => {
    if (numre.length === 0) return ""
    if (numre.length === 1) return " " + numre[0]
    const allExceptLast = numre.slice(0, -1).join(", ")
    const last = numre[numre.length - 1]
    return ` ${allExceptLast} ${i18n.t("og", { ns: "nøkkelbegreper" })} ${last}`
  }

  const sortBruksenhetsnumre = (numre: string[]): string[] => {
    return numre.sort((a, b) => {
      if (a.startsWith("H") && !b.startsWith("H")) return -1
      if (!a.startsWith("H") && b.startsWith("H")) return 1
      return a.localeCompare(b)
    })
  }

  const sorterteBruksenhetsnumre = sortBruksenhetsnumre(bruksenhetsnumre)
  return formaterBruksenhetsnumre(sorterteBruksenhetsnumre)
}

function formaterMatrikkeladresse(
  matrikkelenhetadresse: Matrikkeladresse,
  matrikkelnummer: Matrikkelnummer | undefined,
): string {
  return matrikkelnummer
    ? `${matrikkelnummer.gardsnummer}/${matrikkelnummer.bruksnummer}/${matrikkelnummer.festenummer}-${matrikkelenhetadresse.undernummer}`
    : ""
}

export function formaterFlereVegadresser(
  grupperteVegnavn: GrupperteVegnavn,
  matrikkelenhetstype: string,
): string {
  const grupperteFormatterteAdresser = Object.keys(grupperteVegnavn).reduce((acc, vegnavn) => {
    const vegnummerListe = grupperteVegnavn[vegnavn].sort((a, b) => {
      return sorterVegadresser(a, b)
    })
    acc.push(
      `${vegnavn} ${formaterMedKommaOgOgKunStoreBokstaver(
        vegnummerListe.map(
          vegadresse =>
            `${vegadresse.husnummer}${vegadresse.bokstav ?? ""}${vegadresse.bruksenhetsnummer ? utledBruksenhetsnummerForAdresse(vegadresse.bruksenhetsnummer, matrikkelenhetstype) : ""}`,
        ),
      )}`,
    )
    return acc
  }, [] as string[])

  if (grupperteFormatterteAdresser.length === 0) {
    return ""
  } else if (grupperteFormatterteAdresser.length === 1) {
    return grupperteFormatterteAdresser[0]
  } else if (grupperteFormatterteAdresser.length === 2) {
    return grupperteFormatterteAdresser.join(` ${i18n.t("og", { ns: "nøkkelbegreper" })} `)
  } else {
    return formaterMedKommaOgOgKunStoreBokstaver(grupperteFormatterteAdresser)
  }
}

function sorterVegadresser(a: Vegadresse, b: Vegadresse): number {
  if (!a.husnummer || !b.husnummer) return 0
  return Number(a.husnummer) - Number(b.husnummer) || sorterVegadressePåBokstav(a, b)
}

function sorterVegadressePåBokstav(a: Vegadresse, b: Vegadresse): number {
  if (!a.bokstav || !b.bokstav) {
    return 0
  }
  return a.bokstav.localeCompare(b.bokstav)
}

const grupperVegnavn = (vegadresser: Vegadresse[]) => {
  return vegadresser.reduce(
    (acc, vegadresse) => {
      acc[vegadresse.vegnavn] = acc[vegadresse.vegnavn] || []
      acc[vegadresse.vegnavn].push(vegadresse)
      return acc
    },
    {} as { [key: string]: Vegadresse[] },
  )
}
