import { useQuery } from "@tanstack/react-query"
import { usePostHog } from "posthog-js/react"
import { ApiError } from "../../core/error-handling/ApiError"
import { api } from "../../shared-api/router"
import { Bruker } from "../types/Bruker"

export const useBruker = () => {
  const posthog = usePostHog()
  const brukerQuery = useQuery<Bruker, ApiError>({
    queryKey: ["bruker"],
    queryFn: api.bruker.hentBruker,
  })

  if (brukerQuery.isSuccess) {
    posthog.identify(brukerQuery.data.id.toString())
  }

  return brukerQuery
}
