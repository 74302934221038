const keys = {
  "nb-NO": {
    om_tjenesten_link: "Om tjenesten",
    cookieserklaering: "Cookieerklæring",
    personvern_og_sikkerhet_link: "Personvern og sikkerhet",
    personvern_erklæring_link: "Personvernerklæring",
    personvern_erklæring_arialabel: "Besøk Kartverkets nettside om personvern",
    tilgjengelighetserklæring_link: "Tilgjengelighetserklæring",
    tilgjengelighetserklæring_arialabel:
      "Besøk tilgjengelighetserklæring for mineiendom.kartverket.no",
  },
  "nn-NO": {
    om_tjenesten_link: "Om tenesta",
    cookieserklaering: "Cookieerklæring",
    personvern_og_sikkerhet_link: "Personvern og tryggleik",
    personvern_erklæring_link: "Personvernerklæring",
    personvern_erklæring_arialabel: "Besøk Kartverkets nettside om personvern",
    tilgjengelighetserklæring_link: "Tilgjengeleikserklæring",
    tilgjengelighetserklæring_arialabel:
      "Besøk tilgjengeleikserklæring for mineiendom.kartverket.no",
  },
}

type FooterKey = keyof (typeof keys)["nb-NO"]

type Footer = {
  [K in keyof typeof keys]: Record<FooterKey, string>
}

export const footer: Footer = {
  "nb-NO": keys["nb-NO"],
  "nn-NO": keys["nn-NO"],
}
