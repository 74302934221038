import useEgenregistreringBruksenhet from "../api/useEgenregistreringBruksenhet"
import { Kildekode } from "../types/Egenregistrering"
import { Felt } from "../types/schema"

export const useMetadata = (feltKey?: Felt) => {
  const { data: egenregistreringBruksenhet } = useEgenregistreringBruksenhet()

  if (!egenregistreringBruksenhet || !feltKey) return undefined

  const getMetadataFromArray = (
    arr?: {
      metadata?: {
        registreringstidspunkt: string
        registrertAvDeg: boolean
        kildemateriale?: Kildekode
      }
    }[],
  ) => {
    return arr && arr.length > 0 ? arr[0].metadata : undefined
  }

  const bruksarealMetadata = egenregistreringBruksenhet.bruksareal
    ? egenregistreringBruksenhet.bruksareal.metadata
    : egenregistreringBruksenhet.etasjer?.[0].bruksareal.metadata
      ? egenregistreringBruksenhet.etasjer?.[0].bruksareal.metadata
      : undefined

  switch (feltKey) {
    case "byggeår":
      return egenregistreringBruksenhet.byggeår?.metadata
    case "bruksareal":
      return bruksarealMetadata
    case "energikilder":
      return getMetadataFromArray(egenregistreringBruksenhet.energikilder)
    case "oppvarming":
      return getMetadataFromArray(egenregistreringBruksenhet.oppvarming)
    case "vannforsyning":
      return egenregistreringBruksenhet.vannforsyning?.metadata
    case "avløp":
      return egenregistreringBruksenhet.avløp?.metadata
    default:
      return undefined
  }
}
