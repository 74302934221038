import useCurrentBygning from "../../detaljert-eiendom/hooks/useCurrentBygning"
import { useEgenregistreringIsEnabled } from "../api/useEgenregistreringIsEnabled"

const useBygningKanEgenregistreresPå = (bygningId: string | undefined) => {
  const { currentBygning, dineBruksenheterIBygning } = useCurrentBygning(bygningId)
  const { data: egenregistreringIsEnabled } = useEgenregistreringIsEnabled()

  if (!currentBygning || !bygningId || !dineBruksenheterIBygning) return false

  return egenregistreringIsEnabled && dineBruksenheterIBygning.length === 1
}

export default useBygningKanEgenregistreresPå
