import { Box } from "@kvib/react"
import { Brødtekst } from "../../../../core/text/components/Brødtekst"

const HjelpetekstForFelt = ({
  colorSceheme = "blue.50",
  children,
}: {
  colorSceheme?: "green.50" | "blue.50"
  children: string
}) => {
  return (
    <Box mb="30px" bg={colorSceheme} borderRadius="8px">
      <Brødtekst litenVariant p="18px">
        {children}
      </Brødtekst>
    </Box>
  )
}

export default HjelpetekstForFelt
