import { Skeleton } from "@kvib/react"
import { useTranslation } from "react-i18next"
import DetaljPopover from "../../components/DetaljPopover"
import FeltverdiForEiendomsdetaljer from "./FeltverdiForEiendomsdetaljer"
import OverskriftForEiendomsdetaljer from "./OverskriftForEiendomsdetaljer"

const Eierandeldetalj = ({ eierandel }: { eierandel: string | undefined }) => {
  const { t } = useTranslation(["detaljertInnsyn", "nøkkelbegreper"])
  const eierandelFerdigFetched = eierandel !== undefined

  return (
    <DetaljPopover
      posthogName="din_eierandel_info_click"
      forklaring={t("eierandel_forklaring") as string}
      buttonAriaLabel={t("eierandel_forklaring_arialabel") as string}
    >
      <OverskriftForEiendomsdetaljer>{t("din_andel_overskrift")}</OverskriftForEiendomsdetaljer>
      {eierandelFerdigFetched ? (
        <FeltverdiForEiendomsdetaljer
          fieldValue={eierandel ? eierandel : t("ikke_registrert", { ns: "nøkkelbegreper" })}
        />
      ) : (
        <Skeleton w="60%" h="24px" />
      )}
    </DetaljPopover>
  )
}

export default Eierandeldetalj
