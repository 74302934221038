import {
  Flex,
  HStack,
  InputGroup,
  InputProps,
  NumberInput,
  NumberInputField,
  Stack,
} from "@kvib/react"
import { UseFormRegisterReturn } from "react-hook-form"
import { Brødtekst } from "../../../../core/text/components/Brødtekst"

interface InputfeltTallProps extends InputProps {
  name: "byggeår" | "bruksareal.totaltBruksareal" | "bruksareal.etasjeRegistreringer"
  visKvadratmeter?: boolean
  register: UseFormRegisterReturn<string>
}

const InputfeltTall = (props: InputfeltTallProps) => {
  const { name, isDisabled, variant, visKvadratmeter, register } = props
  return (
    <Flex w="fit-content" gap={8} mb="24px">
      <Stack>
        <InputGroup>
          <NumberInput isDisabled={isDisabled} variant={variant}>
            <HStack>
              <NumberInputField
                {...register}
                name={name}
                value={name}
                w="fit-content"
                sx={{
                  _disabled: {
                    color: "black",
                  },
                }}
              />
              {visKvadratmeter && <Brødtekst>m²</Brødtekst>}
            </HStack>
          </NumberInput>
        </InputGroup>
      </Stack>
    </Flex>
  )
}

export default InputfeltTall
