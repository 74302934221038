import { Box, Image, Skeleton } from "@kvib/react"
import { useTranslation } from "react-i18next"
import eiendomSvg from "../../../assets/eiendom.svg"
import { Brødtekst } from "../../core/text/components/Brødtekst"
import { DetaljertEiendomResponse } from "../../eiendom/detaljert-eiendom/types/DetaljertEiendom"
import useTeigpolygoner from "../../interaktivtKart/api/useTeigpolygoner"
import { InteraktivtKart } from "../../interaktivtKart/InteraktivtKart"

const KartutsnittForDetaljertEiendom = ({
  isPending,
  eiendom,
}: {
  isPending: boolean
  eiendom: DetaljertEiendomResponse | undefined
}) => {
  const { t } = useTranslation("eiendomskort")

  const { data: teigpolygoner } = useTeigpolygoner(eiendom?.matrikkelnummer)

  if (isPending) {
    return <Skeleton height="400px" />
  }

  const representasjonspunkt = eiendom?.koordinat

  if (!representasjonspunkt) {
    return (
      <Box
        p="64px"
        bg="green.50"
        borderRadius={8}
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="relative"
      >
        <Brødtekst color="gray.600" align="center">
          {t("mangler_kartutsnitt_for_eiendommen_forklaring")}
        </Brødtekst>
        <Image
          src={eiendomSvg}
          transform="scaleX(-1)"
          opacity=".1"
          position="absolute"
          bottom="0"
          right="0"
          height="100%"
          pt="1rem"
          pl="2%"
          objectFit="contain"
        />
      </Box>
    )
  }

  return <InteraktivtKart teigpolygoner={teigpolygoner ?? undefined} />
}

export default KartutsnittForDetaljertEiendom
