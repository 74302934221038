import { zodResolver } from "@hookform/resolvers/zod"
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  FormLabel,
  NumberInput,
} from "@kvib/react"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import { Brødtekst } from "../../../../../core/text/components/Brødtekst"
import { useEiendom } from "../../../../detaljert-eiendom/api/useEiendom"
import { Etasje } from "../../../../detaljert-eiendom/bygningsdetaljer/types/Bygning"
import { sorterEtterPlankodeDeretterEtasjeummer } from "../../../../detaljert-eiendom/bygningsdetaljer/utils/etasjeUtil"
import useCurrentBygning from "../../../../detaljert-eiendom/hooks/useCurrentBygning"
import { EidDetaljertEiendom } from "../../../../detaljert-eiendom/types/DetaljertEiendom"
import useAlleEgenregistreringerIBygningen from "../../../api/useEgenregistrering"
import { BruksarealSchema, bruksarealSchema } from "../../../types/schema"
import RegistrerKildemateriale from "../../RegistrerKildemateriale"
import HjelpetekstForFelt from "../HjelpetekstForFelt"
import InputfeltTall from "../InputfeltTall"
import { InvalidInputAlert } from "../InvalidInputAlert"
import { BruksarealPerEtasjeInput } from "./BruksarealPerEtasjeInput"
import LeggTilFlereEtasjerAccordion from "./LeggTilFlereEtasjerAccordion"

const BruksarealForm = ({ handleSubmit }: { handleSubmit: (data: BruksarealSchema) => void }) => {
  const { bygningId } = useParams()
  const bruksarealForm = useForm<BruksarealSchema>({
    resolver: zodResolver(bruksarealSchema),
  })

  const { data: eidEiendom } = useEiendom<EidDetaljertEiendom>()
  const { dineBruksenheterIBygning } = useCurrentBygning()
  const [etasjeBruksarealValues, setEtasjeBruksarealValues] = useState<{
    [key: string]:
      | { bruksareal: number; etasjebetegnelse: { etasjeplanKode: string; etasjenummer: number } }
      | undefined
  }>({})
  const { t } = useTranslation(["egenregistrering", "skjemaoverskrifter"])
  const { data: bygningForEgenregistrering } = useAlleEgenregistreringerIBygningen()

  const etasjer = eidEiendom?.bygningerPåEiendom.find(b => b.byggId === bygningId)?.etasjer
  const etasjeForBruksenheten = etasjer?.filter(
    etasje =>
      etasje.etasjenummer === dineBruksenheterIBygning?.[0].etasjenummer &&
      etasje.etasjeplankode === dineBruksenheterIBygning?.[0].bruksenhetsnummer.charAt(0),
  )
  const [valgteEtasjer, setValgteEtasjer] = useState<Etasje[] | undefined>(
    etasjeForBruksenheten && etasjeForBruksenheten?.length > 0 ? etasjeForBruksenheten : undefined,
  )

  useEffect(() => {
    const etasjeRegistreringer = Object.values(etasjeBruksarealValues).map(value => ({
      bruksareal: value?.bruksareal || 0,
      etasjebetegnelse: value?.etasjebetegnelse || { etasjeplanKode: "", etasjenummer: 0 },
    }))

    bruksarealForm.setValue("bruksareal.etasjeRegistreringer", etasjeRegistreringer)
  }, [etasjeBruksarealValues, bruksarealForm])

  const handleAreaChange = ({
    etasjenummer,
    etasjeplankode,
    areal,
  }: {
    etasjenummer: string
    etasjeplankode: string
    areal: string
  }) => {
    setEtasjeBruksarealValues(previousValues => {
      // Lager en unik nøkkel for denne etasjen
      const key = `${etasjeplankode}+${etasjenummer}`

      const newAreaData = {
        bruksareal: Number(areal),
        etasjebetegnelse: {
          etasjeplanKode: etasjeplankode,
          etasjenummer: Number(etasjenummer),
        },
      }

      // Oppdater verdiene med det nye verdiparet
      return {
        ...previousValues,
        [key]: newAreaData,
      }
    })
  }
  if (!eidEiendom || !dineBruksenheterIBygning || !bygningForEgenregistrering || !bygningId)
    return null

  // TODO: Erstatt denne med at bygningen vet om det finnes andre eiere?
  const jegErEnesteEierIBygningen =
    bygningForEgenregistrering?.bruksenheter.length === 1 && dineBruksenheterIBygning.length === 1

  const onSubmit = bruksarealForm.handleSubmit(data => {
    handleSubmit(data)
  })

  return (
    <form id="bruksareal-form" onSubmit={onSubmit}>
      {valgteEtasjer && valgteEtasjer.length > 0 ? (
        <Box>
          <FormLabel>
            {t("registrer_felt_for_bruksenheten.bruksareal_etasje", { ns: "skjemaoverskrifter" })}
          </FormLabel>
          <BruksarealPerEtasjeInput
            name="bruksareal.etasjeRegistreringer"
            handleAreaChange={handleAreaChange}
            etasjer={sorterEtterPlankodeDeretterEtasjeummer(valgteEtasjer)}
          />
        </Box>
      ) : (
        <Box>
          <Brødtekst>{t("hjelpetekst_bruksareal_ingen_registrerte_etasjer")}</Brødtekst>
        </Box>
      )}
      {etasjer && (
        <LeggTilFlereEtasjerAccordion
          etasjer={sorterEtterPlankodeDeretterEtasjeummer(etasjer)}
          valgteEtasjer={valgteEtasjer}
          setValgteEtasjer={setValgteEtasjer}
          defaultVelgAlleEtasjer={jegErEnesteEierIBygningen}
        />
      )}

      <Accordion allowToggle>
        <AccordionItem borderTop="none" borderBottom="none">
          <AccordionButton pl="0">
            <Flex textAlign="left">
              <Brødtekst>{t("får_ikke_registrert_areal_per_etasje_accordion_tittel")}</Brødtekst>
            </Flex>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pl={0}>
            <HjelpetekstForFelt colorSceheme="green.50">
              {t("får_ikke_registrert_areal_per_etasje_hjelpetekst")}
            </HjelpetekstForFelt>
            <Box mt="24px">
              <FormLabel>
                {t("registrer_felt_for_bruksenheten.bruksareal_totalt", {
                  ns: "skjemaoverskrifter",
                })}
              </FormLabel>
              <Flex mt="16px" gap="8px" alignItems="center">
                <NumberInput>
                  <InputfeltTall
                    register={bruksarealForm.register("bruksareal.totaltBruksareal", {
                      valueAsNumber: true,
                    })}
                    name="bruksareal.totaltBruksareal"
                    visKvadratmeter
                  />
                </NumberInput>
              </Flex>
            </Box>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <RegistrerKildemateriale
        feltnavn="bruksarealet"
        register={bruksarealForm.register("kildemateriale.kode")}
      />
      <InvalidInputAlert error={bruksarealForm.formState.errors.kildemateriale?.kode} />
    </form>
  )
}

export default BruksarealForm
