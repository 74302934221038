import { Box, Image } from "@kvib/react"
import { Map, View } from "ol"
import { GeoJSON } from "ol/format"
import TileLayer from "ol/layer/Tile"
import VectorLayer from "ol/layer/Vector"
import VectorSource from "ol/source/Vector"
import XYZ from "ol/source/XYZ"
import { ViewOptions } from "ol/View"
import { useEffect, useMemo, useRef } from "react"
import { useTranslation } from "react-i18next"
import eiendomPlaceholder from "../../../assets/eiendom.svg"
import { Eiendom } from "../../eiendom/eiendomsoversikt/types/Eiendom"
import { formaterAdresseForEiendom } from "../../eiendom/eiendomsoversikt/utils/adresseForEiendomUtils"
import { KART_EXTENT, projeksjon } from "../../interaktivtKart/constants"
import { Polygoner } from "../../interaktivtKart/types/InteraktivtKart"
import { hentBboxForPolygoner, polygonTilGeoJSON, polygonstil } from "../../interaktivtKart/utils"

type Props<K extends string, T> = {
  teig: Polygoner | null
  metadata: Record<K, T>
}

const FellesKartutsnittkomponent = ({ teig, metadata }: Props<"eiendom", Eiendom>) => {
  const { t } = useTranslation("eiendomskort")
  const mapRef = useRef<HTMLDivElement | null>(null)

  const adresser = formaterAdresseForEiendom(
    metadata.eiendom.adresser,
    metadata.eiendom.matrikkelnummer,
    metadata.eiendom.matrikkelenhetstype,
  )

  const extent = useMemo(() => {
    if (teig && teig.length > 0) {
      return hentBboxForPolygoner(teig)
    }
    return null
  }, [teig])

  const overlays = useMemo(() => teig?.map(p => polygonTilGeoJSON(p)), [teig])

  const kart = useMemo(() => {
    const viewConfig: ViewOptions = {
      projection: projeksjon,
      extent: KART_EXTENT,
      maxZoom: 18,
    }

    const newMap = new Map({
      interactions: [],
      controls: [],
      maxTilesLoading: 4,
      layers: [
        new TileLayer({
          source: new XYZ({
            crossOrigin: "*",
            projection: projeksjon,
            url: `https://cache.kartverket.no/v1/wmts/1.0.0/topo/default/utm33n/{z}/{y}/{x}.png`,
          }),
        }),
      ],
      view: new View(viewConfig),
    })

    return newMap
  }, [])

  useEffect(() => {
    if (kart && mapRef.current) {
      kart.setTarget(mapRef.current)
      if (extent) {
        kart.getView().fit(extent, {
          size: kart.getSize(),
          padding: Array(4).fill(50),
        })
      }

      overlays?.forEach(overlay => {
        const source = new VectorSource({
          features: new GeoJSON().readFeatures(overlay),
        })

        kart.addLayer(
          new VectorLayer({
            source: source,
            style: polygonstil,
          }),
        )
      })

      return () => kart.setTarget(undefined)
    }
  }, [kart, extent, overlays])

  if (!overlays) return

  return overlays.length ? (
    <div
      ref={mapRef}
      tabIndex={1}
      style={{ height: "100%", width: "100%" }}
      aria-label={t("kartutsnitt_eiendom_alt_tittel_med_adresse", { adresse: adresser })}
    />
  ) : (
    <Box bg="green.50" position="relative" height="100%">
      <Image
        src={eiendomPlaceholder}
        alt={t("mangler_kartutsnitt_eiendom_alt_tittel_med_adresse", { adresse: adresser })}
        position="absolute"
        bottom="0"
        pl="2%"
        pr="5%"
        left="0"
        width="80%"
        height="70%"
        opacity={0.1}
      />
    </Box>
  )
}

export default FellesKartutsnittkomponent
