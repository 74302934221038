import { Kodelister } from "../../../../shared-api/types/ApiResponseTypes"
import { hentUtfraKodeliste } from "../../../kodelister/utils"
import { Bygning, BygningsstatusHistorikk } from "../types/Bygning"
import { gyldigeBygningsstatuser } from "./gyldigeBygningsstatuser"

export const hentGjeldendeBygningsstatus = (
  bygningsstatusHistorikk: BygningsstatusHistorikk[],
  kodelister: Kodelister,
) => {
  const bygningsHistorikk = bygningsstatusHistorikk
    .slice()
    .reverse()
    .filter(e => gyldigeBygningsstatuser.includes(e.status))

  const latestHistorikk = bygningsHistorikk[0]
  if (!latestHistorikk) return { år: null, status: "" }

  const { status, år } = latestHistorikk
  const mappedStatus = hentUtfraKodeliste(status, kodelister.bygningsstatusKodeliste)

  return { år: år, status: mappedStatus }
}

export const hentAlleBygningsstatuser = (bygning: Bygning, kodelister: Kodelister) => {
  const bygningsstatusHistorikkUtenEBStatuser = bygning.bygningsstatusHistorikk.filter(e =>
    gyldigeBygningsstatuser.includes(e.status),
  )

  return bygningsstatusHistorikkUtenEBStatuser
    .reverse()
    .map(statusHistorikk => hentGjeldendeBygningsstatus([statusHistorikk], kodelister))
}
