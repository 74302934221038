import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Energikildetype } from "../types/Enums"
import { EnergikilderSchema, energikilderSchema } from "../types/schema"
import RegistrerKildemateriale from "./RegistrerKildemateriale"
import Flervalg from "./skjemakomponenter/Flervalg"
import HjelpetekstForFelt from "./skjemakomponenter/HjelpetekstForFelt"
import { InvalidInputAlert } from "./skjemakomponenter/InvalidInputAlert"

const RegistrerEnergikilder = ({
  handleSubmit,
}: {
  handleSubmit: (data: EnergikilderSchema) => void
}) => {
  const { t } = useTranslation("egenregistrering")
  const form = useForm<EnergikilderSchema>({ resolver: zodResolver(energikilderSchema) })

  const onSubmit = form.handleSubmit(data => {
    handleSubmit(data)
  })

  return (
    <form id="energikilder-form" onSubmit={onSubmit}>
      <HjelpetekstForFelt>{t("hjelpetekst_energikilder")}</HjelpetekstForFelt>
      <Flervalg
        name="energikilder"
        options={Energikildetype}
        register={form.register("energikilder")}
      />
      <RegistrerKildemateriale
        feltnavn="energikildene"
        register={form.register("kildemateriale.kode")}
      />
      <InvalidInputAlert error={undefined} />
    </form>
  )
}

export default RegistrerEnergikilder
