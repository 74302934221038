import { Map, View } from "ol"
import { defaults as defaultControls } from "ol/control"
import { GeoJSON } from "ol/format"
import { defaults as defaultInteractions } from "ol/interaction"
import TileLayer from "ol/layer/Tile"
import VectorLayer from "ol/layer/Vector"
import "ol/ol.css"
import { XYZ } from "ol/source"
import VectorSource from "ol/source/Vector"
import { useEffect, useMemo } from "react"

import { Box } from "@kvib/react"
import { useTranslation } from "react-i18next"
import { useEiendom } from "../eiendom/detaljert-eiendom/api/useEiendom"
import { NorgeskartKnapp } from "../kartutsnitt/components/NorgeskartKnapp"
import { ResetZoomControl } from "./components/ResetZoomControl"
import { KART_EXTENT, kartpadding, projeksjon } from "./constants"
import { Polygoner } from "./types/InteraktivtKart"
import { hentBboxForPolygoner, polygonTilGeoJSON, polygonstil } from "./utils"

export const InteraktivtKart = ({ teigpolygoner }: { teigpolygoner?: Polygoner }) => {
  const { data: eiendom, isPending: isEiendommerPending } = useEiendom()
  const overlays = useMemo(() => teigpolygoner?.map(p => polygonTilGeoJSON(p)), [teigpolygoner])
  const extent = useMemo(() => hentBboxForPolygoner(teigpolygoner), [teigpolygoner])
  const { t } = useTranslation("eiendomskort")

  useEffect(() => {
    if (!eiendom?.koordinat) return

    const viewConfig = {
      projection: projeksjon,
      maxZoom: 18,
      extent: KART_EXTENT,
      center: [eiendom.koordinat.long, eiendom.koordinat.lat],
      ...(!teigpolygoner && {
        zoom: 15,
      }),
    }

    const map = new Map({
      layers: [
        new TileLayer({
          source: new XYZ({
            crossOrigin: "*",
            projection: projeksjon,
            url: `https://cache.kartverket.no/v1/wmts/1.0.0/topo/default/utm33n/{z}/{y}/{x}.png`,
          }),
        }),
      ],
      view: new View(viewConfig),
      target: "map",
      interactions: defaultInteractions({
        mouseWheelZoom: false,
      }),
      controls: defaultControls().extend([new ResetZoomControl(extent ?? KART_EXTENT)]),
    })

    if (extent) {
      map.getView().fit(extent, {
        size: map.getSize(),
        padding: Array(4).fill(kartpadding),
      })
    }
    overlays?.forEach(overlay => {
      const source = new VectorSource({
        features: new GeoJSON().readFeatures(overlay),
      })
      map.addLayer(
        new VectorLayer({
          source: source,
          style: polygonstil,
        }),
      )
    })
    return () => {
      map.setTarget(undefined)
    }
  }, [eiendom?.koordinat, extent, overlays, teigpolygoner])

  if (isEiendommerPending) {
    return null
  }

  return (
    <Box
      className="ph-no-capture"
      pos="relative"
      overflow="hidden"
      aspectRatio={["4/3", "2/1", "2/1", "5/2"]}
    >
      <div id="map" tabIndex={1} style={{ height: "100%", width: "100%" }}>
        {eiendom?.koordinat && (
          <NorgeskartKnapp koordinat={eiendom?.koordinat}>{t("vis_i_kart_knapp")}</NorgeskartKnapp>
        )}
      </div>
    </Box>
  )
}
