import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Spacer,
} from "@kvib/react"
import { usePostHog } from "posthog-js/react"
import { ReactNode } from "react"
import { useFontStyles } from "../../../../core/text/fontStyles"

export const AccordionInneIBygningskort = ({
  children,
  accordionTittel,
  åpenFraStart = false,
}: {
  children: ReactNode
  accordionTittel: string
  åpenFraStart?: boolean
}) => {
  const { getFontSize, getFontWeight } = useFontStyles()
  const borderColor = "#E3E0E0"
  const posthog = usePostHog()

  return (
    <Accordion allowToggle maxW="100%" overflow="hidden" defaultIndex={åpenFraStart ? 0 : 1}>
      <AccordionItem
        borderBottomWidth="1px"
        borderTopWidth="0px"
        borderBottomColor={borderColor}
        borderTopColor={borderColor}
      >
        <h4>
          <AccordionButton textAlign="left" pl="0" onClick={() => posthog.capture(accordionTittel)}>
            <Box
              wordBreak="break-word"
              fontSize={getFontSize("Brødtekst")}
              whiteSpace="normal"
              fontWeight={getFontWeight("h4")}
              color="black"
            >
              {accordionTittel}
            </Box>
            <Spacer />
            <AccordionIcon />
          </AccordionButton>
        </h4>
        <AccordionPanel p="1" pb="1rem">
          {children}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}
