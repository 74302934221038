import { apiRoute } from "../../../../environments"
import { ErrorResponse } from "../../../shared-api/types/ApiResponseTypes"
import { ApiError } from "../../../core/error-handling/ApiError"
import { Eiendom } from "../types/Eiendom"

export const eiendommerRouter = {
  hentEiendommer: async () => {
    const res = await fetch(`${apiRoute}/eiendommer`)

    if (!res.ok) {
      const body: ErrorResponse = await res.json()
      throw new ApiError(body)
    }

    const data: Eiendom[] = await res.json()
    return data.map((eiendom, index) => ({
      ...eiendom,
      eiendomsIndex: index + 1,
    }))
  },
}
