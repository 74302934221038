const keys = {
  "nb-NO": {
    bygningskort_arialabel: "Bygning {{index, number}}: {{bygningstype}}",
    bygningskort_overskrift: "Om bygningen",
    bygningsdetaljer_overskrift: "Bygningsdetaljer",
    bygningsstatus_forklaring: `Den gjeldende statusen beskriver hvordan bygget er registrert for bruk, om det er under bygging eller skal rives. Statusen avhenger blant annet av hvilket regelverk som gjaldt da bygningen ble oppført. Les mer under "Hjelp og støtte".`,
    vannforsyning_forklaring:
      "Hvilken type vannforsyning bygget benytter seg av. Privat vannverk defineres som nett som forsyner mer enn 100 personer eller 20 husstander.",
    avløp_forklaring: "Hvilken avløpsordning bygget benytter seg av.",
    energi_forklaring: "Hvilke energikilder bygget benytter seg av.",
    oppvarming_forklaring: "Hvilken oppvarmingskilde som utgjør hovedoppvarming for bygget.",
    bygningstype_forklaring:
      "Alle bygninger har en bygningstype som forklarer bygningens funksjon.",
    bygningsnummer_forklaring:
      "Et bygningsnummer er bygningens unike nummer og brukes for å identifisere bygningen.",
    bruksareal_for_bygning_forklaring:
      "Det totale registrerte bruksarealet internt i bygningen. Dette er summen av totalt areal for alle etasjer og kan inkludere areal for loft- og kjellerbod, innglasset balkong og lignende.",
    antall_etasjer_forklaring:
      "Antall registrerte etasjer i bygningen. Eventuelle kjeller- og loftetasjer telles med i antall etasjer.",
    vis_bygning_i_kart_knapp: "Vis bygning i kart",
    kartutsnitt_bygning_alt_tittel: "Kartutsnitt av bygning",
    historiske_bygningsstatuser_tooltip: "Vis tidligere statuser",
    historiske_bygningsstatuser_arialabel: "Vis historikk for bygningsstatus",
    historiske_bygningsstatuser_overskrift: "Tidligere registrerte statuser",
    ingen_historiske_bygningsstatuser: "Ingen tidligere registrerte statuser",
    eiendommen_har_ingen_bygninger: "Ingen registrerte bygninger",
    ingen_adresse: "Ingen adresse",
    antall_etasjer_one: "{{count}} etasje",
    antall_etasjer_other: "{{count}} etasjer",
    antall_bygninger_på_eiendommen_one: "{{count}} bygning på eiendommen",
    antall_bygninger_på_eiendommen_other: "{{count}} bygninger på eiendommen",
    om_bygningen_accordion_overskrift: "Om bygningen",
    egenskaper_ved_bygningen_accordion_overskrift: "Egenskaper ved bygningen",
    bygningstype_felt: "Bygningstype",
    bygningsnummer_felt: "Bygningsnummer",
    bygningsstatus_felt: "Bygningsstatus",
    feilmelding_ved_manglende_kartutsnitt: [
      "Klarte ikke hente kartutsnitt for bygningen.",
      "Prøv å laste inn siden på nytt.",
    ],
  },
  "nn-NO": {
    bygningskort_arialabel: "",
    bygningskort_overskrift: "",
    bygningsdetaljer_overskrift: "",
    bygningsstatus_forklaring: "",
    vannforsyning_forklaring: "",
    avløp_forklaring: "",
    energi_forklaring: "",
    oppvarming_forklaring: "",
    bygningstype_forklaring: "",
    bygningsnummer_forklaring: "",
    bruksareal_for_bygning_forklaring: "",
    antall_etasjer_forklaring: "",
    vis_bygning_i_kart_knapp: "",
    kartutsnitt_bygning_alt_tittel: "",
    historiske_bygningsstatuser_tooltip: "",
    historiske_bygningsstatuser_arialabel: "",
    historiske_bygningsstatuser_overskrift: "",
    ingen_historiske_bygningsstatuser: "",
    eiendommen_har_ingen_bygninger: "",
    ingen_adresse: "",
    antall_etasjer_one: "",
    antall_etasjer_other: "",
    antall_bygninger_på_eiendommen_one: "",
    antall_bygninger_på_eiendommen_other: "",
    om_bygningen_accordion_overskrift: "",
    egenskaper_ved_bygningen_accordion_overskrift: "",
    bygningstype_felt: "",
    bygningsnummer_felt: "",
    bygningsstatus_felt: "",
    feilmelding_ved_manglende_kartutsnitt: ["", ""],
  },
}

export const bygningskort = {
  "nb-NO": keys["nb-NO"],
  "nn-NO": keys["nn-NO"],
}
