import { zodResolver } from "@hookform/resolvers/zod"
import { FormLabel } from "@kvib/react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { ByggeårSchema, byggeårSchema } from "../types/schema"
import RegistrerKildemateriale from "./RegistrerKildemateriale"
import HjelpetekstForFelt from "./skjemakomponenter/HjelpetekstForFelt"
import InputfeltTall from "./skjemakomponenter/InputfeltTall"
import { InvalidInputAlert } from "./skjemakomponenter/InvalidInputAlert"

const RegistrerByggeår = ({ handleSubmit }: { handleSubmit: (data: ByggeårSchema) => void }) => {
  const { t } = useTranslation("egenregistrering")

  const byggeårForm = useForm<ByggeårSchema>({
    resolver: zodResolver(byggeårSchema),
  })
  const onSubmit = byggeårForm.handleSubmit(data => {
    handleSubmit(data)
  })
  return (
    <form id="byggeår-form" onSubmit={onSubmit}>
      <HjelpetekstForFelt>
        {t("hjelpetekst_byggeår", { ns: "egenregistrering" })}
      </HjelpetekstForFelt>
      <FormLabel>
        {t("registrer_felt_for_bruksenheten.byggeår", { ns: "skjemaoverskrifter" })}
      </FormLabel>
      <InputfeltTall
        register={byggeårForm.register("byggeår", { valueAsNumber: true })}
        name="byggeår"
        placeholder="F. eks. 1993"
      />
      <InvalidInputAlert error={byggeårForm.formState.errors.byggeår} />
      <RegistrerKildemateriale
        feltnavn="byggeåret"
        register={byggeårForm.register("kildemateriale.kode")}
      />
      <InvalidInputAlert error={byggeårForm.formState.errors.kildemateriale?.kode} />
    </form>
  )
}

export default RegistrerByggeår
