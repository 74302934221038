import { useQuery } from "@tanstack/react-query"
import { useParams } from "react-router"
import { konverterFraMinutterTilMillisekunder } from "../../../../utils/konverterFraMinutterTilMillisekunder"
import { ApiError } from "../../../core/error-handling/ApiError"
import { api } from "../../../shared-api/router"
import { useEiendommer } from "../../eiendomsoversikt/api/useEiendommer"
import { BortfestetDetaljertEiendom, EidDetaljertEiendom } from "../types/DetaljertEiendom"

export const useEiendom = <T = EidDetaljertEiendom | BortfestetDetaljertEiendom>() => {
  const eiendommerQuery = useEiendommer()
  const { eiendomsIndex } = useParams()

  const matrikkelenhetId =
    eiendommerQuery.data &&
    eiendommerQuery.data.find(e => e.eiendomsIndex === Number(eiendomsIndex))?.matrikkelenhetId

  return useQuery<T, ApiError>({
    queryKey: ["eiendom", matrikkelenhetId],
    staleTime: konverterFraMinutterTilMillisekunder(15),
    queryFn: () => api.eiendom.hentEiendom(matrikkelenhetId!) as Promise<T>,
    throwOnError: true,
    enabled: !!matrikkelenhetId,
  })
}
