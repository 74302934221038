import { useTranslation } from "react-i18next"
import { BruksarealSchema } from "../../../types/schema"
import HjelpetekstForFelt from "../HjelpetekstForFelt"
import BruksarealForm from "./BruksarealForm"

const RegistrerBruksareal = ({
  handleSubmit,
}: {
  handleSubmit: (data: BruksarealSchema) => void
}) => {
  const { t } = useTranslation("egenregistrering")

  return (
    <>
      <HjelpetekstForFelt colorSceheme="blue.50">{t("hjelpetekst_bruksareal")}</HjelpetekstForFelt>
      <BruksarealForm handleSubmit={handleSubmit} />
    </>
  )
}

export default RegistrerBruksareal
