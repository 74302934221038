import { Outlet, Route } from "react-router"
import Root from "../../Root"
import Cookieserklæring from "../cookies"
import CookieBanner from "../cookies/components/CookieBanner"
import ErrorHandler from "../core/error-handling/ErrorHandler"
import { finnerIngenPathsSomMatcher } from "../core/error-handling/types/finnerIngenPathSomPatcher"
import Datafelter from "../datafelter/Datafelter"
import DetaljertEiendom from "../eiendom/detaljert-eiendom/components/DetaljertEiendom"
import MineEiendommer from "../eiendom/eiendomsoversikt/components/MineEiendommer"
import Egenregistrering from "../eiendom/registrering"
import HjelpOgStøtte from "../infosider/hjelp-og-stotte/HjelpOgStøtte"
import OmTjenesten from "../infosider/om-tjenesten/OmTjenesten"
import PersonvernOgSikkerhetInfoside from "../infosider/personvern/PersonvernOgSikkerhetInfoside"
import { api } from "../shared-api/router"
import ProtectedRoute from "./ProtectedRoute"

const Routes = (
  <Route path="/" handle="Min eiendom" element={<Root />}>
    <Route
      element={
        <>
          <Outlet />
          <CookieBanner />
        </>
      }
    >
      <Route element={<ProtectedRoute />} errorElement={<ErrorHandler />} handle="protected">
        <Route index element={<MineEiendommer />} />
        <Route path="eiendom/:eiendomsIndex" handle="Eiendom">
          <Route index element={<DetaljertEiendom />} />
          <Route
            loader={async () => {
              const kanEgenregistrere = await api.egenregistrering.kanEgenregistrere()
              if (!kanEgenregistrere) {
                throw finnerIngenPathsSomMatcher
              }
              return null
            }}
            path=":bygningId/:bruksenhetId"
            handle="Egenregistrering"
            element={<Egenregistrering />}
          />
        </Route>
        <Route path="hjelp" handle="Hjelp og støtte">
          <Route index element={<HjelpOgStøtte />} />
          <Route
            path="datafelter"
            element={<Datafelter />}
            handle="Hva betyr de forskjellige datafeltene?"
          />
        </Route>
      </Route>
      <Route element={<Outlet />}>
        <Route path="om-tenesta" element={<OmTjenesten />} handle="Om tenesta" />
        <Route path="personvern" element={<PersonvernOgSikkerhetInfoside />} handle="Personvern" />
        <Route path="cookies" element={<Cookieserklæring />} handle="Cookieerklæring" />
        <Route
          path="*"
          loader={() => {
            throw finnerIngenPathsSomMatcher
          }}
          errorElement={<ErrorHandler />}
          handle="Fant ikke side"
        />
      </Route>
    </Route>
  </Route>
)

export default Routes
