import { Flex } from "@kvib/react"
import { UseFormRegisterReturn } from "react-hook-form"

interface RadioProps {
  feltKey: string
  label: string
  register: UseFormRegisterReturn<string>
}
const Radio = (props: RadioProps) => {
  const { feltKey, label, register } = props
  return (
    <Flex>
      <input
        style={{ width: "16px", accentColor: "green", marginRight: "4px" }}
        id={feltKey}
        type="radio"
        key={feltKey}
        value={feltKey}
        {...register}
      />
      <label htmlFor={feltKey}>{label}</label>
    </Flex>
  )
}

export default Radio
