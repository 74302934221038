import { useQuery } from "@tanstack/react-query"
import { useParams } from "react-router"
import { ApiError } from "../../../core/error-handling/ApiError"
import { api } from "../../../shared-api/router"
import { EgenregistreringBygning } from "../types/Egenregistrering"

const useAlleEgenregistreringerIBygningen = () => {
  const { bygningId } = useParams()

  return useQuery<EgenregistreringBygning, ApiError>({
    queryKey: ["egenregistrering", bygningId],
    queryFn: () => api.egenregistrering.hentEgenregistrering(bygningId!),
    throwOnError: true,
  })
}

export default useAlleEgenregistreringerIBygningen
