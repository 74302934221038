import { Skeleton } from "@kvib/react"
import { Overskrift } from "../../../core/text/components/Overskrift"
import { formaterAdresseForEiendom } from "../../eiendomsoversikt/utils/adresseForEiendomUtils"
import { DetaljertEiendomResponse } from "../types/DetaljertEiendom"

export const AdresseOverskrift = ({
  eiendom,
}: {
  eiendom: DetaljertEiendomResponse | undefined
}) => {
  if (!eiendom) {
    return (
      <Skeleton
        mt="2px"
        h={{
          base: "30px",
          md: "36px",
        }}
        w="240px"
      />
    )
  }

  const adresse = formaterAdresseForEiendom(
    eiendom.adresser,
    eiendom.matrikkelnummer,
    eiendom.matrikkelenhetstype,
  )
  return (
    <Overskrift className="ph-no-capture" variant="h1" noOfLines={3}>
      {adresse}
    </Overskrift>
  )
}
