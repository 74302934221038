import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Oppvarmingstype } from "../types/Enums"
import { OppvarmingSchema, oppvarmingSchema } from "../types/schema"
import RegistrerKildemateriale from "./RegistrerKildemateriale"
import Flervalg from "./skjemakomponenter/Flervalg"
import HjelpetekstForFelt from "./skjemakomponenter/HjelpetekstForFelt"
import { InvalidInputAlert } from "./skjemakomponenter/InvalidInputAlert"

const RegistrerOppvarming = ({
  handleSubmit,
}: {
  handleSubmit: (data: OppvarmingSchema) => void
}) => {
  const { t } = useTranslation(["egenregistrering", "skjemaoverskrifter"])
  const form = useForm<OppvarmingSchema>({ resolver: zodResolver(oppvarmingSchema) })

  const onSubmit = form.handleSubmit(data => {
    handleSubmit(data)
  })
  return (
    <form id="oppvarming-form" onSubmit={onSubmit}>
      <HjelpetekstForFelt>{t("hjelpetekst_oppvarmingstyper")}</HjelpetekstForFelt>
      <Flervalg
        name="oppvarming"
        options={Oppvarmingstype}
        register={form.register("oppvarming")}
      />
      <RegistrerKildemateriale
        feltnavn="oppvarming"
        register={form.register("kildemateriale.kode")}
      />
      <InvalidInputAlert error={undefined} />
    </form>
  )
}

export default RegistrerOppvarming
