import { Box, Icon, MaterialSymbol } from "@kvib/react"

const CircularIcon = ({ icon, iconSize }: { icon: MaterialSymbol; iconSize?: number }) => (
  <Box
    borderRadius="full"
    p={1}
    mr={2}
    bg="green.100"
    color="black"
    display="inline-flex"
    alignItems="center"
    justifyContent="center"
  >
    <Icon size={iconSize} icon={icon} />
  </Box>
)

export default CircularIcon
