import { Center } from "@kvib/react"
import { useTranslation } from "react-i18next"
import Section from "../../core/components/layout/Section"
import StackMedGap from "../../core/components/layout/StackMedGap"
import { Brødtekst } from "../../core/text/components/Brødtekst"
import { EksternLenke } from "../../core/text/components/EksternLenke"
import { Overskrift } from "../../core/text/components/Overskrift"
import { InfoCard } from "../components/InfoCard"
import { FantDuIkkeSvar, OversiktOverDineEiendommer } from "../components/InfoteksterSamlefil"

const OmTjenesten = () => {
  const { t } = useTranslation(["omTjenesten", "hjelpOgStøtte"], { lng: "nn-NO" })

  return (
    <Section pb={["16px", "160px"]}>
      <Center>
        <StackMedGap>
          <Overskrift variant="h1">{t("overskrift", { ns: "omTjenesten" })}</Overskrift>
          <InfoCard
            cardheader={
              t("oversikt_over_dine_eiendommer", {
                ns: "omTjenesten",
                returnObjects: true,
              }).tittel
            }
          >
            <OversiktOverDineEiendommer nynorsk />
          </InfoCard>
          <InfoCard
            cardheader={
              t("informasjon_om_eiendommen", {
                ns: "omTjenesten",
                returnObjects: true,
              }).tittel
            }
          >
            {t("informasjon_om_eiendommen", {
              ns: "omTjenesten",
              returnObjects: true,
            }).avsnitt.map(beskrivelse => (
              <>
                <Brødtekst>{beskrivelse}</Brødtekst>
                <br />
              </>
            ))}
            <EksternLenke tilAdresse="https://www.kartverket.no/eiendom/eiendomsgrenser/matrikkelbrev">
              {t("hjelpOgStøtte:mer_om_matrikkelbrev_link", { lng: "nn-NO" })}
            </EksternLenke>
          </InfoCard>
          <InfoCard
            cardheader={
              t("fant_du_ikke_svar", {
                ns: "hjelpOgStøtte",
                returnObjects: true,
              }).tittel
            }
          >
            <FantDuIkkeSvar nynorsk />
          </InfoCard>
        </StackMedGap>
      </Center>
    </Section>
  )
}

export default OmTjenesten
