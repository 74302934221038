import { Dispatch, createContext } from "react"
import { Action, Consent, initialConsent } from "../consentReducer"

interface ConsentContext {
  consent: Consent
  dispatch: Dispatch<Action>
}

export const ConsentContext = createContext<ConsentContext>({
  consent: initialConsent(),
  dispatch: () => {},
})
