import { SimpleGrid } from "@kvib/react"
import { useTranslation } from "react-i18next"
import { Overskrift } from "../../../core/text/components/Overskrift"
import { DetaljertEiendomResponse } from "../types/DetaljertEiendom"
import AntallTeigerDetalj from "./AntallTeigerDetalj"
import EiendomsarealDetalj from "./Eiendomsarealdetalj"
import Matrikkelenhetstypedetalj from "./Matrikkelenhetstypedetalj"
import Matrikkelnummerdetalj from "./Matrikkelnummerdetalj"

const NøkkelinfoOmEiendommen = ({ eiendom }: { eiendom: DetaljertEiendomResponse | undefined }) => {
  const { t } = useTranslation(["nøkkelbegreper", "detaljertInnsyn"])

  return (
    <>
      <Overskrift color="gray.600" variant="h4" mb="8px">
        {t("nøkkelinfo_tittel", { ns: "detaljertInnsyn" })}
      </Overskrift>
      <SimpleGrid columns={{ base: 2, md: 2, lg: 4 }} columnGap="20px" rowGap="20px" width="100%">
        <Matrikkelnummerdetalj key="matrikkelnummer" matrikkelnummer={eiendom?.matrikkelnummer} />
        <Matrikkelenhetstypedetalj
          key="matrikkelenhetstype"
          matrikkelenhetstype={eiendom?.matrikkelenhetstype}
        />
        <EiendomsarealDetalj
          key="eiendomsareal"
          erEierseksjon={eiendom?.matrikkelenhetstype === "Eierseksjon"}
          areal={eiendom?.areal?.teigareal}
        />
        {eiendom && eiendom?.antallTeiger > 1 && (
          <AntallTeigerDetalj key="antallTeiger" antallTeiger={eiendom.antallTeiger} />
        )}
      </SimpleGrid>
    </>
  )
}

export default NøkkelinfoOmEiendommen
