import { Flex, Skeleton } from "@kvib/react"
import { useTranslation } from "react-i18next"
import PopoverikonMedTooltip from "../../../core/components/PopoverikonMedTooltip"
import DetaljPopover from "../../components/DetaljPopover"
import { useKodelister } from "../../kodelister/useKodelister"
import { hentUtfraKodeliste } from "../../kodelister/utils"
import FeltverdiForEiendomsdetaljer from "./FeltverdiForEiendomsdetaljer"
import OverskriftForEiendomsdetaljer from "./OverskriftForEiendomsdetaljer"

const DinRettighet = ({ eierforholdtype }: { eierforholdtype: string | undefined }) => {
  const { t } = useTranslation(["detaljertInnsyn", "nøkkelbegreper"])
  const { data: kodelister } = useKodelister()

  const eiertype =
    kodelister && eierforholdtype
      ? (() => {
          const eierforholdkode = hentUtfraKodeliste(
            eierforholdtype,
            kodelister.eierforholdKodeliste,
          )
          return ["Hjemmelshaver", "Aktuell eier"].includes(eierforholdkode) ? "Eier" : "Fester"
        })()
      : undefined

  return (
    <DetaljPopover
      posthogName="din_rettighet_info_click"
      forklaring={t("din_rettighet_forklaring", { ns: "detaljertInnsyn" })}
      buttonAriaLabel="Hvilken type eier du er"
    >
      <OverskriftForEiendomsdetaljer>
        {t("eiertype_tittel", { ns: "detaljertInnsyn" })}
      </OverskriftForEiendomsdetaljer>
      {kodelister && eiertype ? (
        <Flex alignItems="center">
          <FeltverdiForEiendomsdetaljer fieldValue={eiertype} />
          <PopoverikonMedTooltip
            icon="help"
            tooltipLabel={t("hva_betyr_type_arialabel", {
              ns: "nøkkelbegreper",
              type: `${eiertype}`,
            })}
            tooltipAriaLabel={t("hva_betyr_type_arialabel", {
              ns: "nøkkelbegreper",
              type: `${eiertype}`,
            })}
            iconButtonAriaLabel={t("hva_betyr_type_arialabel", {
              ns: "nøkkelbegreper",
              type: `${eiertype}`,
            })}
          >
            {t(`eiertype_forklaring.${eiertype.toLowerCase()}` as never, {
              ns: "detaljertInnsyn",
            })}
          </PopoverikonMedTooltip>
        </Flex>
      ) : (
        <Skeleton w="60%" h="24px" />
      )}
    </DetaljPopover>
  )
}

export default DinRettighet
