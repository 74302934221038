import { formaterMedKommaOgOg } from "../../../utils/formateringUtils"
import { Kodelister } from "../../shared-api/types/ApiResponseTypes"
import { Bygning } from "../detaljert-eiendom/bygningsdetaljer/types/Bygning"
import { avløpMapping } from "../detaljert-eiendom/bygningsdetaljer/utils/avløpsUtil"
import { energikildeMapping } from "../detaljert-eiendom/bygningsdetaljer/utils/energikilderUtil"
import { etasjeplankodeMapping } from "../detaljert-eiendom/bygningsdetaljer/utils/etasjeUtil"
import { hentGjeldendeBygningsstatus } from "../detaljert-eiendom/bygningsdetaljer/utils/hentBygningsstatus"
import { vannforsyningkodeMapping } from "../detaljert-eiendom/bygningsdetaljer/utils/vannforsyningUtil"
import { Bruksenhet } from "../detaljert-eiendom/types/DetaljertEiendom"
import { hentUtfraKodeliste } from "../kodelister/utils"
import { EgenregistreringBruksenhet, EtasjeRegistrering } from "./types/Egenregistrering"
import { Avløpstype, Energikildetype, Oppvarmingstype, Vannforsyningstype } from "./types/Enums"
import { Felt } from "./types/schema"

const ikkeRegistrertMatrikkelverdi = "Ikke registrert"

export const visMatrikkelregistrertVerdi = (
  key: Felt,
  bygning: Bygning,
  dineBruksenheterIBygning: Bruksenhet[],
  kodelister: Kodelister,
) => {
  switch (key) {
    case "byggeår": {
      const { år, status } = hentGjeldendeBygningsstatus(
        bygning.bygningsstatusHistorikk,
        kodelister,
      )
      return år ? `${år}: ${status}` : ikkeRegistrertMatrikkelverdi
    }

    case "bruksareal": {
      const totaltBruksareal = dineBruksenheterIBygning.reduce((sum, enhet) => {
        const area = parseFloat(enhet.bruksareal)
        return sum + (isNaN(area) ? 0 : area)
      }, 0)

      return totaltBruksareal > 0 ? `${totaltBruksareal} m²` : ikkeRegistrertMatrikkelverdi
    }

    case "energikilder": {
      const energikilder = bygning.energikilder || []
      return energikilder.length > 0
        ? formaterMedKommaOgOg(
            energikilder.map(energikilde => energikildeMapping[energikilde] || energikilde),
          )
        : ikkeRegistrertMatrikkelverdi
    }

    case "oppvarming": {
      const oppvarmingstyper = bygning.oppvarming || []
      return oppvarmingstyper.length > 0
        ? formaterMedKommaOgOg(
            oppvarmingstyper.map(type =>
              hentUtfraKodeliste(type, kodelister.oppvarmingstypeKodeliste),
            ),
          )
        : ikkeRegistrertMatrikkelverdi
    }

    case "vannforsyning":
      return bygning.vannforsyning
        ? vannforsyningkodeMapping[bygning.vannforsyning] || ikkeRegistrertVerdi
        : ikkeRegistrertMatrikkelverdi

    case "avløp":
      return bygning.avløp
        ? avløpMapping[bygning.avløp] || ikkeRegistrertVerdi
        : ikkeRegistrertMatrikkelverdi

    default:
      return ikkeRegistrertMatrikkelverdi
  }
}

export const ikkeRegistrertVerdi = "Ikke registrert av eier"

export const hentEgenregistrertVerdi = (key: Felt, bruksenhet: EgenregistreringBruksenhet) => {
  switch (key) {
    case "byggeår":
      return bruksenhet.byggeår?.data ?? ikkeRegistrertVerdi
    case "bruksareal":
      return hentTotaltBruksareal(bruksenhet) ?? ikkeRegistrertVerdi
    case "vannforsyning":
      return bruksenhet.vannforsyning?.data
        ? Vannforsyningstype[bruksenhet.vannforsyning.data]
        : ikkeRegistrertVerdi
    case "avløp":
      return bruksenhet.avløp?.data ? Avløpstype[bruksenhet.avløp.data] : ikkeRegistrertVerdi
    case "energikilder": {
      const energikilder = bruksenhet.energikilder || []

      return energikilder.length > 0
        ? formaterMedKommaOgOg(energikilder.map(energikilde => Energikildetype[energikilde.data]))
        : ikkeRegistrertVerdi
    }
    case "oppvarming": {
      const oppvarmingstyper = bruksenhet.oppvarming || []
      return oppvarmingstyper.length > 0
        ? formaterMedKommaOgOg(oppvarmingstyper.map(oppvarming => Oppvarmingstype[oppvarming.data]))
        : ikkeRegistrertVerdi
    }
    default:
      return ikkeRegistrertVerdi
  }
}

function hentTotaltBruksareal(bruksenhet: EgenregistreringBruksenhet) {
  if (bruksenhet.bruksareal?.data?.totaltBruksareal) {
    return `${bruksenhet.bruksareal.data.totaltBruksareal} m²`
  }

  if (bruksenhet.bruksareal?.data?.etasjeRegistrertBruksareal) {
    const totaltBruksarealFraEtasjer = bruksenhet.bruksareal.data.etasjeRegistrertBruksareal.reduce(
      (sum, etasjeregistrering) => sum + (etasjeregistrering.bruksareal || 0),
      0,
    )

    return `${totaltBruksarealFraEtasjer} m²`
  }

  return ikkeRegistrertVerdi
}

export function hentBruksarealForEtasjeregistrering(
  etasjeRegistrering: EtasjeRegistrering,
): string | string[] | undefined {
  const etasjebetegnelse = etasjeRegistrering.etasjebetegnelse
  const etasje = hentEtasjering(etasjebetegnelse)
  const bruksarealIEtasje = etasjeRegistrering.bruksareal
  return `${etasje}: ${bruksarealIEtasje} m² \n`
}

function hentEtasjering(etasjeFraBruksenhet?: string): string | null {
  if (!etasjeFraBruksenhet) return null

  const firstLetter = etasjeFraBruksenhet[0]
  const etasjenummer = etasjeFraBruksenhet.slice(1)

  const etasjeplan =
    etasjeplankodeMapping[firstLetter as keyof typeof etasjeplankodeMapping] || "Ukjent etasje"

  return `${etasjenummer}. ${etasjeplan}`
}

export const felterForEgenregistrering: Felt[] = [
  "byggeår",
  "bruksareal",
  "vannforsyning",
  "avløp",
  "energikilder",
  "oppvarming",
]
