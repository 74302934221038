const keys = {
  "nb-NO": {
    registrer_felt_for_bruksenheten: {
      byggeår: "Skriv inn byggeåret for bruksenheten",
      bruksareal_etasje: "Skriv inn bruksareal per etasje for bruksenheten",
      bruksareal_totalt: "Skriv inn totalt bruksareal for bruksenheten",
      vannforsyning: "Velg bruksenhetens vannforsyning",
      avløp: "Velg bruksenhetens avløp",
      oppvarming: "Velg hovedoppvarming for bruksenheten",
      energikilder: "Velg energikilder for bruksenheten",
    },
  },
  "nn-NO": {
    registrer_felt_for_bruksenheten: "",
  },
} as const

export const skjemaoverskrifter = {
  "nb-NO": keys["nb-NO"],
  "nn-NO": keys["nn-NO"],
}
