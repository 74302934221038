import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbProps,
  Flex,
  Icon,
} from "@kvib/react"
import { NavLink, useLocation, useMatches } from "react-router"
import useIsInnlogget from "../../hooks/useIsInnlogget"
import { useScreenView } from "../../hooks/useScreenview"

import { useTranslation } from "react-i18next"
import { Brødtekst } from "../../text/components/Brødtekst"

export const NavBreadcrumb = (props: BreadcrumbProps) => {
  const location = useLocation()
  const matches = useMatches()
  const { t } = useTranslation("errormeldinger")
  const loggedIn = useIsInnlogget()
  const { isDesktop } = useScreenView()
  const breadcrumbFontSize = isDesktop ? "md" : "sm"

  if (loggedIn.isPending) return null

  const isInnlogget = loggedIn.isSuccess && loggedIn.data

  const activeRoutes = matches.filter(match => match.handle)
  const breadcrumb = matches.filter(match => match.handle != "protected")

  return (
    <Breadcrumb
      color="green.500"
      h="24px"
      fontSize={breadcrumbFontSize}
      separator={
        <Flex justifyContent="center" alignContent="center" w="8px" h="100%">
          <Icon icon="chevron_right" weight={400} ariaIsHidden />
        </Flex>
      }
      sx={{ "> ol:first-of-type": { display: "flex", flexWrap: "wrap" } }}
      {...props}
    >
      {breadcrumb.map((route, index) => {
        if (typeof route.handle === "string")
          return (
            <BreadcrumbItem key={route.pathname} gap={1} as={Flex}>
              {index === 0 && <Icon icon="home" isFilled color="green.500" ariaIsHidden />}
              {location.pathname === route.pathname ? (
                <Brødtekst color="gray.800">
                  {activeRoutes.some(match => match.handle === "protected") && !isInnlogget
                    ? t("fant_ikke_side")
                    : route.handle}
                </Brødtekst>
              ) : (
                <BreadcrumbLink as={NavLink} to={route.pathname}>
                  {route.handle}
                </BreadcrumbLink>
              )}
            </BreadcrumbItem>
          )
      })}
    </Breadcrumb>
  )
}
