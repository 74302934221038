const keys = {
  "nb-NO": {
    bruksareal_tittel: "Totalt bruksareal",
    antall_etasjer_tittel: "Antall etasjer",
    vannforsyning_tittel: "Vannforsyning",
    avløp_tittel: "Avløp",
    energikilder_tittel: "Energikilder",
    oppvarmingstyper_tittel: "Oppvarming",
  },
  "nn-NO": {
    bruksareal_tittel: "",
    antall_etasjer_tittel: "",
    vannforsyning_tittel: "",
    avløp_tittel: "",
    energikilder_tittel: "",
    oppvarmingstyper_tittel: "",
  },
}

type EgenskaperVedBygningenKey = keyof (typeof keys)["nb-NO"]

type EgenskaperVedBygningen = {
  [K in keyof typeof keys]: Record<EgenskaperVedBygningenKey, string>
}

export const egenskaperVedBygning: EgenskaperVedBygningen = {
  "nb-NO": keys["nb-NO"],
  "nn-NO": keys["nn-NO"],
}
