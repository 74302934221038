import { Center, Grid, GridItem, Spinner, useBreakpointValue } from "@kvib/react"
import { useQueryClient } from "@tanstack/react-query"
import { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { konverterFraMinutterTilMillisekunder } from "../../../../utils/konverterFraMinutterTilMillisekunder"
import ErrorHandler from "../../../core/error-handling/ErrorHandler"
import { api } from "../../../shared-api/router"
import { useEiendommer } from "../api/useEiendommer"
import Eiendomskort from "./Eiendomskort"
import HvorforSerJegIkkeEiendomCard from "./HvorforSerJegIkkeEiendomCard"

const EiendomskortListe = () => {
  const { data: eiendommer, isLoading, isSuccess, isError, error } = useEiendommer()
  const queryClient = useQueryClient()
  const { t } = useTranslation("eiendomskort")

  const eiendommerLengde = eiendommer?.length
  const skalVisesSomRad =
    useBreakpointValue({ base: false, md: true, lg: (eiendommerLengde ?? 0) < 3 }) ?? false

  const flexDir = useBreakpointValue({
    base: "column",
    md: "row",
    lg: skalVisesSomRad ? "row" : "column",
  }) as "column" | "row"

  const imgRef = useRef<HTMLImageElement>(null)

  useEffect(() => {
    eiendommer?.forEach(eiendom => {
      queryClient.prefetchQuery({
        queryKey: ["eiendom", eiendom.matrikkelenhetId],
        queryFn: () => api.eiendom.hentEiendom(eiendom.matrikkelenhetId),
        staleTime: konverterFraMinutterTilMillisekunder(15),
      })
    })
  }, [eiendommer, queryClient])

  if (isError) return <ErrorHandler apiError={error} />

  if (isLoading || !isSuccess)
    return (
      <Center h="100%" w="100%">
        <Spinner />
      </Center>
    )

  return (
    <Grid
      templateColumns={{
        base: "repeat(1, 1fr)",
        lg: skalVisesSomRad ? "repeat(1, 1fr)" : "repeat(2, 1fr)",
        xl: skalVisesSomRad ? "repeat(1, 1fr)" : "repeat(3, 1fr)",
      }}
      autoRows="max-content"
      gap={{ base: "16px", lg: skalVisesSomRad ? "16px" : "32px", xl: "16px" }}
      justifyItems="center"
      aria-label={t("mine_eiendommer_arialabel", { count: eiendommer.length })}
      data-cy="eiendomskort-liste"
      ref={imgRef}
    >
      {eiendommer.map(eiendom => (
        <GridItem h="100%" w="100%" key={eiendom.matrikkelenhetId}>
          <Eiendomskort flexDir={flexDir} eiendom={eiendom} />
        </GridItem>
      ))}
      <GridItem
        h="100%"
        w="100%"
        bg="blue.50"
        textAlign="center"
        p="2"
        borderRadius="var(--kvib-radii-base)"
        boxShadow="var(--kvib-shadows-md)"
      >
        <HvorforSerJegIkkeEiendomCard
          skalVisesSomRad={skalVisesSomRad}
          visLogo={eiendommer.length < 3}
        />
      </GridItem>
    </Grid>
  )
}

export default EiendomskortListe
