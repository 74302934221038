import { Box, SimpleGrid } from "@kvib/react"
import { useTranslation } from "react-i18next"
import { Overskrift } from "../../../core/text/components/Overskrift"
import { Eierforhold } from "../types/DetaljertEiendom"
import BortfestetDetalj from "./BortfestetDetalj"
import DinRettighet from "./DinRettighet"
import Eierandeldetalj from "./Eierandeldetalj"

const EierforholdDetalj = ({ eierforhold }: { eierforhold: Eierforhold | undefined }) => {
  const { t } = useTranslation("detaljertInnsyn")

  return (
    <Box width="100%">
      <Overskrift color="gray.600" variant="h4" mb="16px">
        {t("eierforhold_tittel")}
      </Overskrift>
      <SimpleGrid columns={{ base: 2, md: 2, lg: 4 }} columnGap="20px" rowGap="20px" width="100%">
        <DinRettighet eierforholdtype={eierforhold?.type} />
        <Eierandeldetalj eierandel={eierforhold?.eierandel} />
        {eierforhold?.erFestetBort && <BortfestetDetalj />}
      </SimpleGrid>
    </Box>
  )
}

export default EierforholdDetalj
