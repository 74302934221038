import { Box, Flex, useDisclosure } from "@kvib/react"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { formaterStorForbokstav } from "../../../../utils/formateringUtils"
import { Brødtekst } from "../../../core/text/components/Brødtekst"
import useErTomVerdi from "../hooks/useErTomVerdi"
import { EgenregistreringBruksenhet } from "../types/Egenregistrering"
import { Felt } from "../types/schema"
import {
  hentBruksarealForEtasjeregistrering,
  hentEgenregistrertVerdi,
  ikkeRegistrertVerdi,
} from "../utils"
import { EgenregistreringKnapp } from "./EgenregistreringKnapp"
import EgenregistreringModal from "./modaler/EgenregistreringModal"
import Registreringsinfo from "./Registreringsinfo"

const EgenregistrertVerdi = ({
  egenregistreringForBruksenhet,
  felt,
}: {
  egenregistreringForBruksenhet: EgenregistreringBruksenhet
  felt: Felt
}) => {
  const { t } = useTranslation("egenregistrering")
  const isTomVerdi = useErTomVerdi()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [inputField, setInputField] = useState<Felt | undefined>(undefined)

  const handleCloseModal = () => {
    setInputField(undefined)
    onClose()
  }

  const egenregistrertVerdi = hentEgenregistrertVerdi(felt, egenregistreringForBruksenhet)

  const tekstForEgenregistreringsknapp = (egenregistrertVerdi: string): string => {
    if (egenregistrertVerdi === ikkeRegistrertVerdi) {
      return formaterStorForbokstav(t("tekst_registrer_verdi_knapp"))
    }

    return formaterStorForbokstav(t("tekst_endre_verdi_knapp"))
  }

  return (
    <Flex p="12px" bg="gray.100" justifyContent="space-between" minW="500px">
      <Flex flexDir="column">
        <Brødtekst w="250px" fontWeight="bold">
          {egenregistrertVerdi}
        </Brødtekst>
        {felt === "bruksareal" &&
          egenregistreringForBruksenhet?.bruksareal?.data.etasjeRegistrertBruksareal && (
            <Box>
              {egenregistreringForBruksenhet?.bruksareal?.data.etasjeRegistrertBruksareal.map(
                (etasje, index) => (
                  <Brødtekst key={index}>{hentBruksarealForEtasjeregistrering(etasje)}</Brødtekst>
                ),
              )}
            </Box>
          )}
        <Registreringsinfo feltKey={felt} />
      </Flex>
      <EgenregistreringKnapp
        data-cy={`egenregistrering-${felt}`}
        icon={isTomVerdi(felt) ? "add" : "edit"}
        onClick={() => {
          setInputField(felt)
          onOpen()
        }}
        textAlign="right"
        ml="auto"
      >
        {tekstForEgenregistreringsknapp(egenregistrertVerdi as string)}
      </EgenregistreringKnapp>
      {inputField && (
        <EgenregistreringModal felt={inputField} isOpen={isOpen} onClose={handleCloseModal} />
      )}
    </Flex>
  )
}

export default EgenregistrertVerdi
