import { apiRoute } from "../../../environments"
import { ApiError } from "../../core/error-handling/ApiError"
import { ErrorResponse, Kodelister } from "../../shared-api/types/ApiResponseTypes"

export const kodelisterMatrikkelRouter = {
  hentKodelister: async () => {
    const kodelisterMatrikkel = await fetch(`${apiRoute}/kodelister`)

    if (!kodelisterMatrikkel.ok) {
      const body: ErrorResponse = await kodelisterMatrikkel.json()
      throw new ApiError(body)
    }

    const kodelister: Kodelister = await kodelisterMatrikkel.json()

    return kodelister
  },
}
