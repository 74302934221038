import { useMutation, useQueryClient } from "@tanstack/react-query"
import posthog from "posthog-js"
import { useTranslation } from "react-i18next"
import { apiRouteLogout } from "../../../environments"

const useHandleLogout = () => {
  const { t } = useTranslation("errormeldinger")
  const queryClient = useQueryClient()
  const handleLogoutMutation = useMutation({
    mutationFn: async () => {
      return fetch(apiRouteLogout, {
        method: "POST",
        headers: { "Content-type": "text/plain" },
      })
        .then(res => {
          if (!res.ok) {
            throw new Error(t("klarte_ikke_å_logge_ut_bruker"))
          }
          return res.text()
        })
        .then(resText => {
          try {
            const url = new URL(resText)
            return url.href
          } catch {
            throw new Error(t("klarte_ikke_å_logge_ut_bruker"))
          }
        })
        .catch(error => {
          throw new Error(error.message)
        })
    },
    onSuccess: res => {
      window.location.assign(res)
      posthog.reset()
    },
    onMutate: () => {
      posthog.stopSessionRecording()
      queryClient.invalidateQueries({ queryKey: ["bruker"] })
    },
  })

  return handleLogoutMutation
}

export default useHandleLogout
