import posthog from "posthog-js"
import { Bruker } from "../user/types/Bruker"

export interface Consent {
  isVisible: boolean
  consentGiven: boolean
  showDetailedConsentPage?: boolean
}

interface Payload {
  bruker: Bruker
}

export type Action =
  | { type: "GIVE_CONSENT"; payload: Payload }
  | { type: "DECLINE_CONSENT" }
  | { type: "SHOW_BANNER" }

export const initialConsent = (): Consent => {
  const consent = localStorage.getItem("cookieConsent")
  return {
    isVisible: consent === null,
    consentGiven: consent === "true",
  }
}

export const reducer = (state: Consent, action: Action): Consent => {
  switch (action.type) {
    case "GIVE_CONSENT":
      posthog.identify(action.payload.bruker.id.toString())
      posthog.opt_in_capturing()
      posthog.startSessionRecording()

      localStorage.setItem("cookieConsent", "true")
      return { isVisible: false, consentGiven: true }
    case "DECLINE_CONSENT":
      posthog.stopSessionRecording()
      posthog.opt_out_capturing()

      localStorage.setItem("cookieConsent", "false")
      return { ...state, isVisible: false, consentGiven: false }
    case "SHOW_BANNER":
      return { ...state, isVisible: true, showDetailedConsentPage: true }
    default:
      return state
  }
}
