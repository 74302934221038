import { Alert, AlertDescription, AlertIcon } from "@kvib/react"
import { FieldError } from "react-hook-form"

export const InvalidInputAlert = ({ error }: { error: FieldError | undefined }) => {
  return (
    error && (
      <Alert mb="12px" mt="12px" status="error" variant="top-accent">
        <AlertIcon />
        <AlertDescription>{error.message}</AlertDescription>
      </Alert>
    )
  )
}
