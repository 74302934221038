const keys = {
  "nb-NO": {
    bruksenhetskort_arialabel: "Bruksenhet {{bruksenhetsnummer}}",
    bruksenhetsnummer_overskrift: "Bruksenhetsnummer",
    bruksenhetsnummer_forklaring:
      "Bokstaven og de to første tallene angir hvilken etasje bruksenheten ligger i. De to siste tallene angir nummer i etasjen, som regel satt fra venstre mot høyre. Unummererte bruksenheter har ikke bruksenhetsnummer.",
    unummerertBruksenhet_forklaring:
      "Denne bruksenheten er unummerert og har færre registrerte opplysninger. Det er i stor grad støtteenheter som for eksempel garasje, uthus og anneks som registreres med denne typen.",
    bruksenhetstype_overskrift: "Type bruksenhet",
    bruksenhetstype_forklaring:
      "Beskriver bruken av bruksenheten. Kan være følgende typer: Bolig, Ikke godkjent bolig, Fritidsbolig, Annet enn bolig eller Unummerert bruksenhet (benyttes for eksempel for garasje).",
    bruksareal_for_en_bruksenhet_overskrift: "Bruksareal",
    bruksareal_for_en_bruksenhet_forklaring:
      "Registrert bruksareal for denne bruksenheten. Arealet kan inkludere innglasset balkong, samt privat garasje og boder.",
    bruksareal_for_en_bruksenhet_arialabel: "Hva er bruksareal for bruksenheten?",
    inngang_etasje_overskrift: "Inngang",
    inngang_etasje_forklaring: "Hvilken etasje inngangen til bruksenheten ligger i.",
    dine_bruksenheter_i_bygningen_accordion_overskrift_one: "Din bruksenhet i bygningen",
    dine_bruksenheter_i_bygningen_accordion_overskrift_other: "Dine bruksenheter i bygningen",
  },
  "nn-NO": {
    bruksenhetskort_arialabel: "",
    bruksenhetsnummer_overskrift: "",
    bruksenhetsnummer_forklaring: "",
    unummerertBruksenhet_forklaring: "",
    bruksenhetstype_overskrift: "",
    bruksenhetstype_forklaring: "",
    bruksareal_for_en_bruksenhet_overskrift: "",
    bruksareal_for_en_bruksenhet_forklaring: "",
    bruksareal_for_en_bruksenhet_arialabel: "",
    inngang_etasje_overskrift: "",
    inngang_etasje_forklaring: "",
    dine_bruksenheter_i_bygningen_accordion_overskrift_one: "",
    dine_bruksenheter_i_bygningen_accordion_overskrift_other: "",
  },
}

type BruksenhetkortKey = keyof (typeof keys)["nb-NO"]

type Bruksenhetskort = {
  [K in keyof typeof keys]: Record<BruksenhetkortKey, string>
}

export const bruksenhetskort: Bruksenhetskort = {
  "nb-NO": keys["nb-NO"],
  "nn-NO": keys["nn-NO"],
}
