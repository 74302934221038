import { useEiendom } from "../detaljert-eiendom/api/useEiendom"
import { EidDetaljertEiendom } from "../detaljert-eiendom/types/DetaljertEiendom"
import useEgenregistreringBruksenhet from "./api/useEgenregistreringBruksenhet"
import EgenregistrerteOpplysninger from "./components/EgenregistrerteOpplysninger"

const Egenregistrering = () => {
  const { data: eidEiendom } = useEiendom<EidDetaljertEiendom>()
  const { data: egenregistreringForBruksenhet } = useEgenregistreringBruksenhet()

  if (!egenregistreringForBruksenhet || !eidEiendom) {
    return null
  }

  return (
    <EgenregistrerteOpplysninger
      eidEiendom={eidEiendom}
      egenregistreringForBruksenhet={egenregistreringForBruksenhet}
    />
  )
}

export default Egenregistrering
