import useEgenregistreringBruksenhet from "../api/useEgenregistreringBruksenhet"
import { Felt } from "../types/schema"
import { hentEgenregistrertVerdi, ikkeRegistrertVerdi } from "../utils"

const useErTomVerdi = () => {
  const { data: egenregistreringForBruksenhet } = useEgenregistreringBruksenhet()

  return (felt: Felt) => {
    if (!egenregistreringForBruksenhet) return true

    const fieldValue = hentEgenregistrertVerdi(felt, egenregistreringForBruksenhet)

    return fieldValue === ikkeRegistrertVerdi || !fieldValue
  }
}

export default useErTomVerdi
