import { Box, Button, Flex, Heading } from "@kvib/react"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router"
import { Brødtekst } from "../../core/text/components/Brødtekst"
import { useBruker } from "../../user/hooks/useBruker"
import { ConsentContext } from "../context/ConsentContext"
import InformasjonskapslerOversikt from "./InformasjonskapslerOversikt"

const CookieBanner = () => {
  const { consent, dispatch } = useContext(ConsentContext)
  const { data: bruker } = useBruker()
  const [show, setShow] = useState(false)

  const { t } = useTranslation("cookiebanner")

  if (!consent.isVisible || !bruker) return null

  const handleAccept = () => {
    dispatch({ type: "GIVE_CONSENT", payload: { bruker } })
  }

  return (
    <Box
      position="fixed"
      zIndex="10"
      m={{ base: 0, md: 2 }}
      w={{ base: "100%", md: "60%", lg: "45%" }}
      right={0}
      bottom={0}
      bg="white"
      boxShadow="var(--kvib-shadows-lg)"
      border="1px"
      borderColor="grey"
      p={4}
      borderRadius={{ base: "none", md: "7px" }}
    >
      <Flex mb={4} justifyContent="space-between" alignItems="center">
        <Heading size="md">{t("overskrift")}</Heading>
      </Flex>

      <>
        <Brødtekst mb={4}>
          {t("informasjonskapsler_info")}{" "}
          <Link to="/cookies">
            <u>{t("les_mer_cookieerklaering_lenke")}</u>
          </Link>
        </Brødtekst>

        {!(show || consent.showDetailedConsentPage) && (
          <Flex justifyContent="flex-end">
            <Button
              variant="secondary"
              m={2}
              size="md"
              onClick={() => setShow(true)}
              aria-label={t("tilpass_aria")}
            >
              {t("tilpass")}
            </Button>
            <Button variant="secondary" m={2} size="md" onClick={handleAccept}>
              {t("godta")}
            </Button>
          </Flex>
        )}
      </>

      {(show || consent.showDetailedConsentPage) && (
        <InformasjonskapslerOversikt setShow={setShow} bruker={bruker} />
      )}
    </Box>
  )
}

export default CookieBanner
