import { Skeleton } from "@kvib/react"
import { t } from "i18next"
import { Overskrift } from "../../../core/text/components/Overskrift"
import AlleBygningskortMedKartutsnittStack from "../bygningsdetaljer/components/AlleBygningskortMedKartutsnittStack"
import { Bygning } from "../bygningsdetaljer/types/Bygning"

interface Props {
  bygningerPåEiendom: Bygning[] | undefined
}

const Bygningsdetaljer = ({ bygningerPåEiendom }: Props) => {
  if (!bygningerPåEiendom) return <Skeleton width="100%" />
  return (
    <>
      <Overskrift variant="h2">
        {t("bygningsdetaljer_overskrift", { ns: "bygningskort" })}
      </Overskrift>
      <AlleBygningskortMedKartutsnittStack bygningerPåEiendom={bygningerPåEiendom} />
    </>
  )
}

export default Bygningsdetaljer
