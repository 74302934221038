import { Flex, Skeleton } from "@kvib/react"
import { useTranslation } from "react-i18next"
import PopoverikonMedTooltip from "../../../core/components/PopoverikonMedTooltip"
import DetaljPopover from "../../components/DetaljPopover"
import { getMatrikkelenhetstype } from "../../eiendomsoversikt/utils/eiendomUtils"
import FeltverdiForEiendomsdetaljer from "./FeltverdiForEiendomsdetaljer"
import OverskriftForEiendomsdetaljer from "./OverskriftForEiendomsdetaljer"

const Matrikkelenhetstypedetalj = ({
  matrikkelenhetstype,
}: {
  matrikkelenhetstype: string | undefined
}) => {
  const { t } = useTranslation(["detaljertInnsyn", "nøkkelbegreper"])

  const matrikkelenhetstypeTranslationString =
    matrikkelenhetstype?.toLocaleLowerCase() === "annenmatrikkelenhet"
      ? "annen_matrikkelenhet"
      : matrikkelenhetstype?.toLocaleLowerCase()

  const matrikkelenhetstypeAriaLabel =
    matrikkelenhetstype?.toLocaleLowerCase() === "annenmatrikkelenhet"
      ? "annen type"
      : matrikkelenhetstype?.toLocaleLowerCase()

  return (
    <DetaljPopover
      forklaring={
        matrikkelenhetstype
          ? t("matrikkelenhetstype_forklaring_begrep", { ns: "nøkkelbegreper" })
          : t("laster_inn_forklaring", { ns: "nøkkelbegreper" })
      }
      buttonAriaLabel={t("matrikkelenhetstype_forklaring_begrep_arialabel", {
        ns: "nøkkelbegreper",
      })}
    >
      <OverskriftForEiendomsdetaljer>
        {t("type_eiendom", { ns: "nøkkelbegreper" })}
      </OverskriftForEiendomsdetaljer>
      {matrikkelenhetstype ? (
        <Flex alignItems="center">
          <FeltverdiForEiendomsdetaljer fieldValue={getMatrikkelenhetstype(matrikkelenhetstype)} />
          <PopoverikonMedTooltip
            icon="help"
            tooltipLabel={t("hva_betyr_type_arialabel", {
              ns: "nøkkelbegreper",
              type: `${matrikkelenhetstypeAriaLabel}`,
            })}
            tooltipAriaLabel={t("hva_betyr_type_arialabel", {
              ns: "nøkkelbegreper",
              type: `${matrikkelenhetstypeAriaLabel}`,
            })}
            iconButtonAriaLabel={t("hva_betyr_type_arialabel", {
              ns: "nøkkelbegreper",
              type: `${matrikkelenhetstypeAriaLabel}`,
            })}
          >
            {t(`matrikkelenhetstype_forklaring.${matrikkelenhetstypeTranslationString}` as never, {
              ns: "detaljertInnsyn",
            })}
          </PopoverikonMedTooltip>
        </Flex>
      ) : (
        <Skeleton w="100%" h="24px" />
      )}
    </DetaljPopover>
  )
}

export default Matrikkelenhetstypedetalj
