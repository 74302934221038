const keys = {
  "nb-NO": {
    nøkkelinfo_tittel: "Nøkkelinfo",
    teig_tittel: "Merknader",
    festegrunner_tittel: "Festegrunner",
    bygning_tittel: "Bygninger",
    matrikkelenhetstype_forklaring: {
      grunneiendom:
        "Dette er en grunneiendom. Denne er avgrenset med eiendomsgrenser på jordoverflaten. Grunneiendommen strekker seg etter eiendomsgrensene så langt ned i bakken og opp i luften som eiendomsretten rekker. Les mer under “Hjelp og støtte”.",
      festegrunn:
        "Dette er en festegrunn. Feste betyr å leie. En festegrunn er en del av en grunneiendom eller et jordsameie som noen har en langvarig og eksklusiv rett til å bruke, eller rett til å feste (leie) bort til noen andre. Les mer under “Hjelp og støtte”.",
      eierseksjon:
        "Dette er en eierseksjon. En eierseksjon er en del av en eiendom som er seksjonert. Den som eier en eierseksjon, har rett til å bruke en bestemt andel av eiendommen. Les mer under “Hjelp og støtte”.",
      anleggseiendom:
        "Dette er en anleggseiendom. En anleggseiendom er et avgrenset volum som er bebygd eller kan utbygges. Anleggseiendommen strekker seg over eller under en annen eiendom. Les mer under “Hjelp og støtte”.",
      jordsameie:
        "Dette er et jordsameie. Et jordsameie er et grunnareal som eies sammen av flere grunneiendommer. Jordsameie er ikke inndelt etter hva hver grunneiendom eier, men alt eies i fellesskap av alle grunneiendommene. Les mer under “Hjelp og støtte”.",
      annen_matrikkelenhet:
        "Eiendommen er av eldre type og er tildelt matrikkelnummer etter tidligere regelverk. Les mer under “Hjelp og støtte”.",
    },
    antall_teiger_forklaring: `Eiendommen har flere teiger og du kan se dem i kartutsnittet. På eiendommer med flere teiger vil den største teigen normalt være hovedteig. `,
    antall_teiger_aria_label: "Antallet teiger på eiendommen",
    antall_teiger_overskrift: "Antall teiger",
    antall_teiger: "{{count, number}} teiger",
    eiendomsareal_overskrift: "Eiendomsareal",
    eiendomsareal_forklaring:
      "Eiendomsareal beregnes på grunnlag av registrerte eiendomsgrenser i matrikkelen. Det kan være mangler i grenseinformasjonen som gjør at arealet ikke stemmer, er usikkert eller det ikke er mulig å beregne areal. Les mer under “Hjelp og støtte”.",
    eiendomsareal_forklaring_arialabel: "Hva er eiendomsareal?",
    eiendomsareal_ikke_beregnet_verdi: "Ikke beregnet",
    eksklusivt_uteareal_overskrift: "Eksklusivt uteareal",
    eksklusivt_uteareal_forklaring:
      "Eksklusivt uteareal er areal du har rett til å disponere tilknyttet seksjonen. For eksempel kan dette være uteområde som hage eller parkering. “Ikke registrert“ kan bety at det ikke er eksklusivt uteareal tilknyttet seksjonen, eller at det ikke er registrert i Eiendomsregisteret.",
    ekslusivt_uteareal_forklaring_arialabel: "Hva er eksklusivt uteareal?",
    teig_med_flere_matrikkelenheter_forklaring:
      "Teigen er registrert i Eiendomsregisteret med tilknytning til mer enn ett matrikkelnummer. Det betyr at grensene mellom dem ikke er registrert. Det kan være du som eier alle eller de er eid av andre.",
    teig_med_flere_matrikkelenheter_aria_label: "Teigdetaljer",
    teig_med_flere_matrikkelenheter_overskrift: "Teigdetaljer",
    teig_med_flere_matrikkelenheter_tekst: "Teig med flere matrikkelenheter",
    din_andel_overskrift: "Din andel",
    eierandel_forklaring_arialabel: "Hva er din andel?",
    eiertype_tittel: "Din rettighet",
    din_rettighet_forklaring: "Hvilken type rettighet du er registrert med på denne eiendommen.",
    eiertype_forklaring: {
      eier: "Du står oppført som eier i Eiendomsregisteret til den matrikkelenheten som vises her.",
      fester:
        "Du står oppført som fester eller fremfester i Eiendomsregisteret. Feste er en rett til å leie en festegrunn eller grunneiendom.",
    },
    eierforhold_tittel: "Eierforhold",
    eierandel_forklaring:
      "Hvor stor andel av eiendommen du har rett på. Andelen hentes fra grunnboken. Hvis eierforholdet eller festeforholdet ikke er tinglyst trenger det ikke være registrert noen andel.",
    bygningstyper_på_eiendommen_overskrift: "Type bygninger tilknyttet eiendommen",
    bygningstyper_på_eiendommen_forklaring:
      "Alle de ulike bygningstypene tilknyttet eiendommen din. Finnes det flere bygninger med samme type vil typen listes opp kun én gang.",
    bygningstyper_på_eiendommen_forklaring_arialabel: "Hva er bygningstyper?",
    arealmerknader_overskrift: "Arealmerknader",
    arealmerknad_begrep_forklaring:
      "Eiendommens areal har en eller flere merknader. Det betyr at arealet har en større usikkerhet eller ikke kan beregnes.",
    arealmerknadstype_forklaring: {
      punktfeste:
        "Alle grensene til teigen er av typen punktfeste. Punktfester har ikke beregnet areal da de kun defineres med ett punkt. Eventuelle avtaler om bruk av areal registreres ikke i eiendomsregisteret for punktfester.",
      hjelpelinje_fiktiv:
        "Avgrensningen til teigen er kun fiktive hjelpelinjer, og det er derfor ikke mulig å beregne areal på eiendommen.",
      omtvistet:
        "En eller flere av grensene til en teig er uavklart, enten ved at den er usikker eller ved at det er en tvist. Arealet vil derfor være usikkert inntil grenseforløpet er avklart gjennom en oppmåling. Les mer under “Arealmerknad” på 'Hjelp og støtte'.",
      noen_fiktive_hjelpelinjer:
        "En eller flere (men ikke alle) av grensene til teigen er av typen fiktiv hjelpelinje. Arealet som beregnes vil derfor være usikkert.",
      hjelpelinje_vannkant:
        "En eller flere av grensene til teigen er av type vannkant. Arealet som beregnes vil derfor være usikkert.",
      hjelpelinje_vegkant:
        "En eller flere av grensene til teigen er av type vegkant. Arealet som beregnes vil derfor være usikkert.",
      har_punktfester:
        "En eller flere (men ikke alle) av grensene til teigen er av type punktfeste fordi det er registrert punktfester på eiendommen. Arealet til punktfestene beregnes inn i arealet til eiendommen og eiendommens areal vil derfor være usikkert.",
      ukjent_arealmerknad: "Ukjent arealmerknad. ",
    },
    vis_forklaring_arealmerknad_aria_label: "Hva er en arealmerknad?",
    flere_opplysninger_overskrift: "Flere opplysninger",
    flere_opplysninger_på_se_eiendom:
      "På Se eiendom kan du se flere offentlige eiendomsopplysninger. Du kan også hente ut grunnboksutskrift for å se hva som er tinglyst på eiendommen.",
    se_eiendom_link: "Åpne eiendommen i Se eiendom",
    antall_festegrunner_på_eiendommen_one: "{{count}} festegrunn fester på eiendommen",
    antall_festegrunner_på_eiendommen_other: "{{count}} festegrunner fester på eiendommen",
    bortfestet_detaljer_er_festet_bort_tittel: "Er festet bort",
    bortfestet_detaljer_festenummer_tittel: "Festenummer",
    bortfestet_detaljer_matrikkelnummer_tittel: "Matrikkelnummer",
    bortfestet_eiendom_forklaring:
      "At eiendommen er festet bort betyr at noen andre har en aktiv festekontrakt (leier) på eiendommen. Det er da begrenset hvilke opplysninger du som eier har rett til å se. For eksempel får du ikke se detaljer om bygninger på eiendommen.",
  },
  "nn-NO": {
    nøkkelinfo_tittel: "",
    teig_tittel: "",
    festegrunner_tittel: "",
    bygning_tittel: "",
    matrikkelenhetstype_forklaring: {
      grunneiendom:
        "Dette er en grunneiendom. Denne er avgrenset med eiendomsgrenser på jordoverflaten. Grunneiendommen strekker seg etter eiendomsgrensene så langt ned i bakken og opp i luften som eiendomsretten rekker. Les mer under “Hjelp og støtte”.",
      festegrunn:
        "Dette er en festegrunn. Feste betyr å leie. En festegrunn er en del av en grunneiendom eller et jordsameie som noen har en langvarig og eksklusiv rett til å bruke, eller rett til å feste (leie) bort til noen andre. Les mer under “Hjelp og støtte”.",
      eierseksjon:
        "Dette er en eierseksjon. En eierseksjon er en del av en eiendom som er seksjonert. Den som eier en eierseksjon, har rett til å bruke en bestemt eierandel av eiendomme. Les mer under “Hjelp og støtte”.",
      anleggseiendom:
        "Dette er en anleggseiendom. En anleggseiendom er et avgrenset volum som er bebygd eller kan utbygges. Anleggseiendommen strekker seg over eller under en annen eiendom. Les mer under “Hjelp og støtte”.",
      jordsameie:
        "Dette er et jordsameie. Et jordsameie er et grunnareal som eies sammen av flere grunneiendommer. Jordsameie er ikke inndelt etter hva hver grunneiendom eier, men alt eies i fellesskap av alle grunneiendommene. Les mer under “Hjelp og støtte”.",
      annen_matrikkelenhet:
        "Eiendommen er av eldre type og er tildelt matrikkelnummer etter tidligere regelverk. Les mer under “Hjelp og støtte”.",
    },
    antall_teiger_forklaring: `Eiendommen har flere teiger enn den du ser i kartutsnittet. Hvis du klikker på lenken "Vis i kart" vil du se alle teigene som er registrert i Eiendomsregisteret.`,
    antall_teiger_aria_label: "Antallet teiger på eiendommen",
    antall_teiger_overskrift: "Antall teiger",
    antall_teiger: "{{count, number}} teiger",
    eiendomsareal_overskrift: "Eiendomsareal",
    eiendomsareal_forklaring: `Eiendomsareal beregnes på grunnlag av registrerte eiendomsgrenser i matrikkelen. Det kan være mangler i grenseinformasjonen som gjør at arealet ikke stemmer, er usikkert eller det ikke er mulig å beregne areal. Les mer under “Hjelp og støtte”.`,
    eiendomsareal_forklaring_arialabel: "Hva er eiendomsareal?",
    eiendomsareal_ikke_beregnet_verdi: "",
    eksklusivt_uteareal_overskrift: "",
    eksklusivt_uteareal_forklaring: `Eksklusivt uteareal er areal du har rett til å disponere tilknyttet seksjonen. For eksempel kan dette være uteområde som hage eller parkering. "Ikke registrert" kan bety at det ikke er eksklusivt uteareal tilknyttet seksjonen, eller at det ikke er registrert i Eiendomsregisteret.`,
    ekslusivt_uteareal_forklaring_arialabel: "Hva er eksklusivt uteareal?",
    teig_med_flere_matrikkelenheter_forklaring:
      "Ein eller fleire av teigane er knytta til fleire matrikkeleiningar. Dersom du eig desse matrikkeleiningane kan du sjå same teig fleire gongar, men med ulike eigedomsdetaljar (t.d. matrikkelnummer).",
    teig_med_flere_matrikkelenheter_aria_label: "Teigdetaljar",
    teig_med_flere_matrikkelenheter_overskrift: "Teigdetaljar",
    teig_med_flere_matrikkelenheter_tekst: "Teig med fleire matrikkeleiningar",
    din_andel_overskrift: "Din andel",
    eierandel_forklaring_arialabel: "Hva er din andel?",
    eiertype_tittel: "Din rettighet",
    din_rettighet_forklaring: "Hvilken type rettighet du er registrert med på denne eiendommen.",
    eiertype_forklaring: {
      eier: "Du står oppført som eier i Eiendomsregisteret til den matrikkelenheten som vises her.",
      fester:
        "Du står oppført som fester eller fremfester i Eiendomsregisteret. Feste er en rett til å leie en festegrunn eller grunneiendom.",
    },
    eierforhold_tittel: "Eierforhold",
    eierandel_forklaring:
      "Hvor stor andel av eiendommen du har rett på. Andelen hentes fra grunnboken. Hvis eierforholdet eller festeforholdet ikke er tinglyst trenger det ikke være registrert noen andel.",
    bygningstyper_på_eiendommen_overskrift: "Type bygninger på eiendommen",
    bygningstyper_på_eiendommen_forklaring:
      "Alle de ulike bygningstypene tilknyttet eiendommen din. Finnes det flere bygninger med samme type vil typen listes opp kun én gang.",
    bygningstyper_på_eiendommen_forklaring_arialabel: "Hva er bygningstyper?",
    arealmerknader_overskrift: "",
    arealmerknad_begrep_forklaring: "",
    arealmerknadstype_forklaring: {
      punktfeste: "",
      hjelpelinje_fiktiv: "",
      omtvistet: "",
      noen_fiktive_hjelpelinjer: "",
      hjelpelinje_vannkant: "",
      hjelpelinje_vegkant: "",
      har_punktfester: "",
      ukjent_arealmerknad: "Ukjent arealmerknad. ",
    },
    vis_forklaring_arealmerknad_aria_label: "",
    flere_opplysninger_overskrift: "",
    flere_opplysninger_på_se_eiendom: "",
    se_eiendom_link: "",
    antall_festegrunner_på_eiendommen_one: "{{count}} festegrunn fester på eiendommen",
    antall_festegrunner_på_eiendommen_other: "{{count}} festegrunner fester på eiendommen",
    bortfestet_detaljer_er_festet_bort_tittel: "Er festet bort",
    bortfestet_detaljer_festenummer_tittel: "Festenummer",
    bortfestet_detaljer_matrikkelnummer_tittel: "Matrikkelnummer",
    bortfestet_eiendom_forklaring: "",
  },
} as const

export const detaljertInnsyn = {
  "nb-NO": keys["nb-NO"],
  "nn-NO": keys["nn-NO"],
}
