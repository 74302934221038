import { Flex } from "@kvib/react"
import { UseFormRegisterReturn } from "react-hook-form"

interface CheckboxProps {
  feltKey: string
  label: string
  name: "energikilder" | "oppvarming"
  register: UseFormRegisterReturn<string>
}
const CheckboxForEgenregistrering = (props: CheckboxProps) => {
  const { feltKey, label, register } = props
  return (
    <Flex>
      <input
        type="checkbox"
        id={feltKey}
        value={feltKey}
        style={{ width: "16px", accentColor: "green", marginRight: "4px" }}
        {...register}
      />
      <label htmlFor={feltKey}>{label}</label>
    </Flex>
  )
}

export default CheckboxForEgenregistrering
