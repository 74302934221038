import { Box, Radio, RadioGroup, Spinner, Stack } from "@kvib/react"
import { useState } from "react"
import { UseFormRegisterReturn } from "react-hook-form"
import { Brødtekst } from "../../../core/text/components/Brødtekst"
import { Overskrift } from "../../../core/text/components/Overskrift"
import { useKodelisterBygning } from "../../kodelister/useKodelisterBygning"

const RegistrerKildemateriale = ({
  feltnavn,
  register,
}: {
  feltnavn: string
  register: UseFormRegisterReturn<string>
}) => {
  const { data: kodelister, isPending, isSuccess } = useKodelisterBygning()
  const [selectedValue, setSelectedValue] = useState<string | null>(null)

  if (isPending || !isSuccess) return <Spinner />

  return (
    <Box mt="32px">
      <Overskrift variant="h4">Kilde</Overskrift>
      <Brødtekst litenVariant>
        Velg hvilken kilde du har brukt for å komme frem til {feltnavn}.
      </Brødtekst>
      <RadioGroup onChange={value => setSelectedValue(value)}>
        <Stack mt="12px" mb="12px">
          {kodelister.map(kilde => (
            <Box
              key={kilde.kode}
              p="6px"
              borderRadius="md"
              bg={selectedValue === kilde.kode ? "green.50" : "white"}
              borderColor={selectedValue === kilde.kode ? "green.400" : "gray.200"}
            >
              <Radio gap="8px" value={kilde.kode} {...register} size="md">
                <Brødtekst as="b" litenVariant>
                  {kilde.presentasjonsnavn}
                </Brødtekst>
                <Brødtekst litenVariant>{kilde.beskrivelse}</Brødtekst>
              </Radio>
            </Box>
          ))}
        </Stack>
      </RadioGroup>
    </Box>
  )
}

export default RegistrerKildemateriale
