import { Stack } from "@kvib/react"
import { BygningskortMedKartutsnitt } from "../../../../kartutsnitt/components/BygningskortMedKartutsnitt"
import { bygningAria, sorterPåBygningstype } from "../utils/bygningerUtils"
import { Bygning } from "../types/Bygning"

interface Props {
  bygningerPåEiendom: Bygning[]
}

const AlleBygningskortMedKartutsnittStack = ({ bygningerPåEiendom }: Props) => {
  return (
    <Stack
      data-cy="bygningskort-liste"
      w="100%"
      justifyContent="center"
      gap="14px"
      mt={{
        base: "12px",
        lg: "24px",
      }}
      aria-label={bygningAria(bygningerPåEiendom)}
    >
      {sorterPåBygningstype(bygningerPåEiendom).map((bygning, index) => {
        return <BygningskortMedKartutsnitt key={bygning.byggId} bygning={bygning} index={index} />
      })}
    </Stack>
  )
}

export default AlleBygningskortMedKartutsnittStack
