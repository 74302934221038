import { Skeleton } from "@kvib/react"
import { t } from "i18next"
import { Brødtekst } from "../../../core/text/components/Brødtekst"

export const KommuneOverskrift = ({ kommunenavn }: { kommunenavn: string | undefined }) =>
  kommunenavn ? (
    <Brødtekst className="ph-no-capture">
      {t("kommune", { ns: "nøkkelbegreper", navn: kommunenavn })}
    </Brødtekst>
  ) : (
    <Skeleton
      mt="2px"
      h={{
        base: "20px",
        md: "28px",
      }}
      w="120px"
    />
  )
