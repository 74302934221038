import { Flex } from "@kvib/react"
import { useTranslation } from "react-i18next"
import { formaterMedKommaOgOg } from "../../../../utils/formateringUtils"
import Section from "../../../core/components/layout/Section"
import { Overskrift } from "../../../core/text/components/Overskrift"
import BygningstypeOgVegadresseOverskrift from "../../detaljert-eiendom/bygningsdetaljer/components/BygningstypeOgAdresseOverskrift"
import { getVegadresserPåBygning } from "../../detaljert-eiendom/bygningsdetaljer/utils/bygningerUtils"
import useCurrentBygning from "../../detaljert-eiendom/hooks/useCurrentBygning"
import { EidDetaljertEiendom } from "../../detaljert-eiendom/types/DetaljertEiendom"
import { EgenregistreringBruksenhet } from "../types/Egenregistrering"
import { felterForEgenregistrering } from "../utils"
import EgenregistreringRad from "./EgenregistreringRad"

const EgenregistrerteOpplysninger = ({
  egenregistreringForBruksenhet,
  eidEiendom,
}: {
  eidEiendom: EidDetaljertEiendom
  egenregistreringForBruksenhet: EgenregistreringBruksenhet
}) => {
  const { currentBygning, dineBruksenheterIBygning } = useCurrentBygning()
  const { t } = useTranslation("egenregistrering")

  if (!currentBygning || !dineBruksenheterIBygning || !eidEiendom.adresser.vegadresser) return null

  const vegadresseForBygning = getVegadresserPåBygning(
    eidEiendom.adresser.vegadresser,
    dineBruksenheterIBygning.map(enhet => enhet.bruksenhetsId),
  )

  const bruksenhetsnummer = dineBruksenheterIBygning.map(enhet => enhet.bruksenhetsnummer)
  const formatertBruksenhetsnummer = formaterMedKommaOgOg(bruksenhetsnummer)

  return (
    <Section mb="24px">
      <Overskrift mb="50px" variant="h1">
        {t("egenregistrering_sidetittel")}
      </Overskrift>
      <BygningstypeOgVegadresseOverskrift
        bygningstype={currentBygning.bygningstype}
        erSeksjon={eidEiendom.matrikkelenhetstype === "Eierseksjon"}
        vegadresse={vegadresseForBygning}
        bruksenhetsnummer={formatertBruksenhetsnummer}
      />
      <Flex direction="column" mt={14} gap={8}>
        {felterForEgenregistrering.map((felt, index) => (
          <EgenregistreringRad
            key={index}
            felt={felt}
            egenregistreringForBruksenhet={egenregistreringForBruksenhet}
          />
        ))}
      </Flex>
    </Section>
  )
}

export default EgenregistrerteOpplysninger
