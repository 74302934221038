import { FormLabel, RadioGroup, Stack } from "@kvib/react"
import { UseFormRegisterReturn } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Avløpstype, Vannforsyningstype } from "../../types/Enums"
import { Felt } from "../../types/schema"
import Radio from "./Radio"

interface Props {
  register: UseFormRegisterReturn<string>
  felt: Felt
  options: typeof Vannforsyningstype | typeof Avløpstype
}

const VannforsyningOgAvløpRadioGroup = ({ options, register, felt }: Props) => {
  const { t } = useTranslation("skjemaoverskrifter")

  const formlabelTekst =
    felt === "avløp"
      ? t("registrer_felt_for_bruksenheten.avløp", { ns: "skjemaoverskrifter" })
      : t("registrer_felt_for_bruksenheten.vannforsyning", { ns: "skjemaoverskrifter" })
  return (
    <>
      <RadioGroup name={felt}>
        <FormLabel>{formlabelTekst}</FormLabel>
        <Stack spacing={2} ml="12px">
          {Object.entries(options).map(([key, label]) => (
            <Radio key={key} feltKey={key} label={label} register={register} />
          ))}
        </Stack>
      </RadioGroup>
    </>
  )
}

export default VannforsyningOgAvløpRadioGroup
