import { Box, Flex, Skeleton } from "@kvib/react"
import { t } from "i18next"
import { useScreenView } from "../../../core/hooks/useScreenview"
import { Brødtekst } from "../../../core/text/components/Brødtekst"
import { Overskrift } from "../../../core/text/components/Overskrift"
import { Matrikkelnummer } from "../../eiendomsoversikt/types/Eiendom"
import SeeiendomButton from "./SeeiendomButton"

const FlereOpplysninger = ({
  matrikkelnummer,
}: {
  matrikkelnummer: Matrikkelnummer | undefined
}) => {
  const isMobile = useScreenView()

  if (!matrikkelnummer) return <Skeleton />

  return (
    <>
      <Overskrift variant="h2">
        {t("flere_opplysninger_overskrift", { ns: "detaljertInnsyn" })}
      </Overskrift>
      <Flex mt="24px" flexDir="column" gap={4}>
        <Brødtekst maxW={isMobile ? "100%" : "65%"}>
          {t("flere_opplysninger_på_se_eiendom", { ns: "detaljertInnsyn" }) as string}
        </Brødtekst>
        <Box className="ph-no-capture">
          <SeeiendomButton matrikkelnummer={matrikkelnummer} />
        </Box>
      </Flex>
    </>
  )
}

export default FlereOpplysninger
