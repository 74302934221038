import { Grid } from "@kvib/react"
import { useTranslation } from "react-i18next"
import { useScreenView } from "../../core/hooks/useScreenview"
import Bygningskort from "../../eiendom/detaljert-eiendom/bygningsdetaljer/components/Bygningskort"
import { Bygning } from "../../eiendom/detaljert-eiendom/bygningsdetaljer/types/Bygning"
import {
  Bygningstypekode,
  bygningstyperMapper,
} from "../../eiendom/detaljert-eiendom/bygningsdetaljer/utils/bygningstypeUtil"
import KartutsnittMedBygningskoordinat from "./KartutsnittMedBygningskoordinat"

export const BygningskortMedKartutsnitt = ({
  bygning,
  index,
}: {
  bygning: Bygning
  index: number
}) => {
  const { t } = useTranslation("bygningskort")
  const { isMobile } = useScreenView()

  return (
    <Grid key={bygning.byggId} gap="2rem" templateColumns={isMobile ? "100%" : "3fr 2fr"}>
      <Bygningskort
        bygningId={bygning.byggId}
        aria-label={t("bygningskort_arialabel", {
          index: index + 1,
          bygningstype:
            bygningstyperMapper[bygning.bygningstype.substring(0, 2) as Bygningstypekode],
        })}
        role="group"
      >
        {isMobile && <KartutsnittMedBygningskoordinat bygningId={bygning.byggId} />}
      </Bygningskort>
      {!isMobile && <KartutsnittMedBygningskoordinat bygningId={bygning.byggId} />}
    </Grid>
  )
}
