import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Checkbox,
  Flex,
  Icon,
} from "@kvib/react"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Brødtekst } from "../../../../../core/text/components/Brødtekst"
import { Etasje } from "../../../../detaljert-eiendom/bygningsdetaljer/types/Bygning"
import { etasjeplankodeMapping } from "../../../../detaljert-eiendom/bygningsdetaljer/utils/etasjeUtil"

type LeggTilFlereEtasjerAccordionProps = {
  etasjer: Etasje[] | undefined
  valgteEtasjer: Etasje[] | undefined
  setValgteEtasjer: React.Dispatch<React.SetStateAction<Etasje[] | undefined>>
  defaultVelgAlleEtasjer?: boolean
}

const LeggTilFlereEtasjerAccordion = ({
  etasjer,
  valgteEtasjer,
  setValgteEtasjer,
  defaultVelgAlleEtasjer = false,
}: LeggTilFlereEtasjerAccordionProps) => {
  const { t } = useTranslation("egenregistrering")

  useEffect(() => {
    if (defaultVelgAlleEtasjer && etasjer) {
      setValgteEtasjer(etasjer)
    }
  }, [defaultVelgAlleEtasjer, etasjer, setValgteEtasjer])

  const handleCheckboxChange = (isChecked: boolean, etasje: Etasje) => {
    setValgteEtasjer(prev =>
      isChecked
        ? prev
          ? [...prev, etasje]
          : [etasje]
        : (prev ?? []).filter(
            valgt =>
              !(
                valgt.etasjenummer === etasje.etasjenummer &&
                valgt.etasjeplankode === etasje.etasjeplankode
              ),
          ),
    )
  }

  const mapEtasjer = etasjer?.map(etasje => {
    const isChecked = valgteEtasjer?.some(
      e => e.etasjenummer === etasje.etasjenummer && e.etasjeplankode === etasje.etasjeplankode,
    )

    return (
      <Flex
        key={`${etasje.etasjenummer}. ${etasjeplankodeMapping[etasje.etasjeplankode]}`}
        alignItems="center"
        justifyContent="space-between"
        mt="14px"
        w="fit-content"
      >
        <Checkbox
          isChecked={isChecked}
          onChange={e => handleCheckboxChange(e.target.checked, etasje)}
        >
          <Brødtekst>{`${etasje.etasjenummer}. ${etasjeplankodeMapping[etasje.etasjeplankode]}`}</Brødtekst>
        </Checkbox>
      </Flex>
    )
  })

  return (
    <Accordion allowToggle>
      <AccordionItem borderTop="none" borderBottom="none">
        <AccordionButton color="green.500" pl={0}>
          <Flex textAlign="left">
            <Brødtekst>{t("legg_til_etasjer_accordion_tittel")}</Brødtekst>
          </Flex>
          <Icon icon="add" />
        </AccordionButton>
        <AccordionPanel pl={0}>
          {mapEtasjer && mapEtasjer.length > 0 ? (
            <Flex mt="8px" direction="column" gap="4px">
              <Brødtekst>{t("registrerte_etasjer_overskrift")}</Brødtekst>
              {mapEtasjer}
            </Flex>
          ) : (
            <Brødtekst mt="12px">{t("bygningen_mangler_registrerte_etasjer")}</Brødtekst>
          )}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}

export default LeggTilFlereEtasjerAccordion
