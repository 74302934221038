import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Grid,
  Skeleton,
  Spacer,
} from "@kvib/react"
import posthog from "posthog-js"
import { useTranslation } from "react-i18next"
import { useScreenView } from "../../../core/hooks/useScreenview"
import { Brødtekst } from "../../../core/text/components/Brødtekst"
import { Overskrift } from "../../../core/text/components/Overskrift"
import { formaterMatrikkelnummer } from "../../eiendomsoversikt/utils/eiendomUtils"
import { Festegrunn } from "../types/DetaljertEiendom"
import OverskriftForEiendomsdetaljer from "./OverskriftForEiendomsdetaljer"
import SeeiendomButton from "./SeeiendomButton"

const FestegrunnerPåEiendomAccordion = ({
  festegrunner,
}: {
  festegrunner: Festegrunn[] | undefined
}) => {
  const { isDesktop } = useScreenView()
  const { t } = useTranslation(["detaljertInnsyn", "nøkkelbegreper"])

  if (!festegrunner) return <Skeleton />

  const sortedFestegrunner = [...festegrunner].sort(
    (a, b) => a.matrikkelnummer.festenummer - b.matrikkelnummer.festenummer,
  )

  const FestegrunnInfo = ({ title, value }: { title: string; value: string | number }) => (
    <Flex flexDir="column">
      <OverskriftForEiendomsdetaljer>{title}</OverskriftForEiendomsdetaljer>
      <Brødtekst fontWeight="bold">{value}</Brødtekst>
    </Flex>
  )

  const antallFestegrunnerPåEiendommen = t("antall_festegrunner_på_eiendommen", {
    count: festegrunner.length,
  })

  return (
    <>
      <Overskrift color="gray.600" variant="h4" mb="8px">
        {t("festegrunner_tittel", { ns: "detaljertInnsyn" })}
      </Overskrift>
      <Accordion allowToggle defaultIndex={1} maxW="600px">
        <AccordionItem borderTop="none">
          <h2>
            <AccordionButton
              textAlign="left"
              pl="0"
              onClick={() => posthog.capture("Åpne festegrunner accordion")}
            >
              <Overskrift variant="h3">{antallFestegrunnerPåEiendommen}</Overskrift>
              <Spacer />
              {isDesktop && <>{t("se_alle", { ns: "nøkkelbegreper" })}</>}
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel p={0}>
            {sortedFestegrunner.map((festegrunn, index) => (
              <Grid
                mt="12px"
                mb="12px"
                key={festegrunn.matrikkelnummer.festenummer}
                templateColumns="repeat(auto-fit, minmax(120px, 1fr))"
                bg={index % 2 === 0 ? "gray.100" : "white"}
                borderRadius="md"
                p={4}
                gap={4}
              >
                <FestegrunnInfo
                  title={t("bortfestet_detaljer_matrikkelnummer_tittel", { ns: "detaljertInnsyn" })}
                  value={formaterMatrikkelnummer(festegrunn.matrikkelnummer)}
                />
                <FestegrunnInfo
                  title={t("bortfestet_detaljer_er_festet_bort_tittel", { ns: "detaljertInnsyn" })}
                  value={
                    festegrunn.erFestetBort
                      ? t("ja", { ns: "nøkkelbegreper" })
                      : t("nei", { ns: "nøkkelbegreper" })
                  }
                />
                <Flex
                  className="ph-no-capture"
                  w="fit-content"
                  gridColumn="span 2"
                  alignItems="center"
                >
                  <SeeiendomButton matrikkelnummer={festegrunn.matrikkelnummer} />
                </Flex>
              </Grid>
            ))}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  )
}

export default FestegrunnerPåEiendomAccordion
