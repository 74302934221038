import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Avløpstype } from "../types/Enums"
import { AvløpSchema, avløpSchema } from "../types/schema"
import RegistrerKildemateriale from "./RegistrerKildemateriale"
import HjelpetekstForFelt from "./skjemakomponenter/HjelpetekstForFelt"
import { InvalidInputAlert } from "./skjemakomponenter/InvalidInputAlert"
import VannforsyningOgAvløpRadioGroup from "./skjemakomponenter/VannforsyningOgAvløpRadioGroup"

const RegistrerAvløp = ({ handleSubmit }: { handleSubmit: (data: AvløpSchema) => void }) => {
  const { t } = useTranslation("egenregistrering")
  const form = useForm<AvløpSchema>({ resolver: zodResolver(avløpSchema) })

  const onSubmit = form.handleSubmit(data => {
    handleSubmit(data)
  })

  return (
    <form id="avløp-form" onSubmit={onSubmit}>
      <HjelpetekstForFelt>{t("hjelpetekst_avløp")}</HjelpetekstForFelt>
      <VannforsyningOgAvløpRadioGroup
        register={form.register("avløp")}
        options={Avløpstype}
        felt="avløp"
      />
      <InvalidInputAlert error={undefined} />
      <RegistrerKildemateriale feltnavn="avløp" register={form.register("kildemateriale.kode")} />
    </form>
  )
}

export default RegistrerAvløp
