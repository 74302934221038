import { Skeleton } from "@kvib/react"
import { useTranslation } from "react-i18next"
import DetaljPopover from "../../components/DetaljPopover"
import { Matrikkelnummer } from "../../eiendomsoversikt/types/Eiendom"
import {
  formaterMatrikkelnummer,
  verboseMatrikkelnummer,
} from "../../eiendomsoversikt/utils/eiendomUtils"
import FeltverdiForEiendomsdetaljer from "./FeltverdiForEiendomsdetaljer"
import OverskriftForEiendomsdetaljer from "./OverskriftForEiendomsdetaljer"

const Matrikkelnummerdetalj = ({ matrikkelnummer }: { matrikkelnummer?: Matrikkelnummer }) => {
  const matrikkelnummerId = "matrikkelnummer-" + matrikkelnummer
  const { t } = useTranslation("nøkkelbegreper")

  return (
    <DetaljPopover
      forklaring={t("matrikkelnummer_forklaring")}
      buttonAriaLabel={t("matrikkelnummer_forklaring_arialabel")}
    >
      <OverskriftForEiendomsdetaljer>{t("matrikkelnummer")}</OverskriftForEiendomsdetaljer>
      {matrikkelnummer ? (
        <FeltverdiForEiendomsdetaljer
          className="ph-no-capture"
          id={matrikkelnummerId}
          aria-label={verboseMatrikkelnummer(formaterMatrikkelnummer(matrikkelnummer))}
          fieldValue={formaterMatrikkelnummer(matrikkelnummer)}
        />
      ) : (
        <Skeleton w="100%" h="24px" />
      )}
    </DetaljPopover>
  )
}

export default Matrikkelnummerdetalj
