import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Vannforsyningstype } from "../types/Enums"
import { VannforsyningSchema, vannforsyningSchema } from "../types/schema"
import RegistrerKildemateriale from "./RegistrerKildemateriale"
import HjelpetekstForFelt from "./skjemakomponenter/HjelpetekstForFelt"
import { InvalidInputAlert } from "./skjemakomponenter/InvalidInputAlert"
import VannforsyningOgAvløpRadioGroup from "./skjemakomponenter/VannforsyningOgAvløpRadioGroup"

const RegistrerVannforsyning = ({
  handleSubmit,
}: {
  handleSubmit: (data: VannforsyningSchema) => void
}) => {
  const { t } = useTranslation("egenregistrering")
  const form = useForm<VannforsyningSchema>({ resolver: zodResolver(vannforsyningSchema) })

  const onSubmit = form.handleSubmit(data => {
    handleSubmit(data)
  })

  return (
    <form id="vannforsyning-form" onSubmit={onSubmit}>
      <HjelpetekstForFelt>{t("hjelpetekst_vannforsyning")}</HjelpetekstForFelt>
      <VannforsyningOgAvløpRadioGroup
        register={form.register("vannforsyning")}
        felt="vannforsyning"
        options={Vannforsyningstype}
      />
      <InvalidInputAlert error={form.formState.errors.vannforsyning} />
      <RegistrerKildemateriale
        feltnavn="vannforsyning"
        register={form.register("kildemateriale.kode")}
      />
    </form>
  )
}

export default RegistrerVannforsyning
