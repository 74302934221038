export const cookiebanner = {
  "nb-NO": {
    overskrift: "Vi bruker informasjonskapsler (cookies)",
    informasjonskapsler_info:
      "Min eiendom bruker informasjonskapsler (cookies) for sikkerhet og analyse. Noen av informasjonskapslene er valgfrie. Du kan når som helst endre samtykket ditt i vår cookieerklæring.",
    les_mer_cookieerklaering_lenke: "Les mer om hvilke informasjonskapsler vi bruker.",
    nodvendige_informasjonskapsler_overskrift: "Nødvendige informasjonskapsler",
    nodvendige_informasjonskapsler_info:
      "Disse informasjonskapselene brukes for å gjøre nettsiden sikker å bruke, og er nødvendige for at tjenesten skal fungere. De er derfor ikke mulig å velge bort.",
    webanalyse: "Webanalyse",
    webanalyse_info:
      "Vi bruker informasjonskapsler for å samle inn statistikk om brukeraktivitet på nettsiden vår. Funksjonaliteten på nettsiden påvirkes ikke dersom du lar være å samtykke.",
    lagre_valg: "Lagre valg",
    lukk_banner_aria: "Lukk cookiebanner",
    tilpass: "Tilpass",
    tilpass_aria: "Tilpass informasjonskapsler",
    godta: "Godta alle",
  },

  "nn-NO": {},
}
