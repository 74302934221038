import { Table, Tbody, Td, Th, Thead, Tr } from "@kvib/react"
import { useTranslation } from "react-i18next"

const CookieTabell = () => {
  const { t } = useTranslation("cookies")
  const cookietabell = t("cookiestabell", { returnObjects: true })
  return (
    <Table variant="striped">
      <Thead>
        <Tr>
          {t("cookiestabell_header", { returnObjects: true }).map((header, index) => (
            <Th key={index}>{header}</Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {cookietabell.map((cookie, index) => (
          <Tr key={index}>
            <Td>{cookie.navn}</Td>
            <Td>{cookie.formal}</Td>
            <Td>{cookie.valgfrihet}</Td>
            <Td>{cookie.utsteder}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}

export default CookieTabell
