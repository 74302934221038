import { Button } from "@kvib/react"
import { useLocation, useNavigate } from "react-router"
import useCurrentBygning from "../../detaljert-eiendom/hooks/useCurrentBygning"
import useBygningKanEgenregistreresPå from "../hooks/useBygningKanEgenregistreresPå"

export const GåTilEgenregistreringKnapp = ({
  bygningId,
  bruksenhetId,
}: {
  bygningId: string | undefined
  bruksenhetId: string | undefined
}) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { currentBygning } = useCurrentBygning(bygningId)
  const bygningKanEgenregistreresPå = useBygningKanEgenregistreresPå(bygningId)

  if (!bygningId || !currentBygning) return null

  return bygningKanEgenregistreresPå ? (
    <Button
      variant="secondary"
      rightIcon="keyboard_arrow_right"
      onClick={() => {
        const url = `${pathname}/${currentBygning.byggId}/${bruksenhetId}`
        navigate(url)
      }}
    >
      Se egenregistrerte opplysninger
    </Button>
  ) : null
}
