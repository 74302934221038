import i18n from "i18next"
import { Vegadresse } from "../../../eiendomsoversikt/types/Eiendom"
import { Bygning } from "../types/Bygning"

export const sorterPåBygningstype = (bygninger: Bygning[]) =>
  bygninger.sort((a, b) => a.bygningstype.localeCompare(b.bygningstype))

export const bygningAria = (bygninger: Bygning[]) =>
  i18n.t("antall_bygninger_på_eiendommen", { ns: "bygningskort", count: bygninger.length })

export const getVegadresserPåBygning = (vegadresser: Vegadresse[], bruksenhetIds: string[]) => {
  return vegadresser?.filter(
    (vegadresse: Vegadresse) =>
      vegadresse.bruksenhetsId && bruksenhetIds.includes(vegadresse.bruksenhetsId),
  )
}

export function formaterBruksareal(bruksareal: string): string {
  const bruksarealFloat = parseFloat(bruksareal)
  return bruksarealFloat === 0.0
    ? i18n.t("ikke_registrert", { ns: "nøkkelbegreper" })
    : i18n.t("areal", {
        ns: "nøkkelbegreper",
        val: bruksarealFloat,
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
      })
}
