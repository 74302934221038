export const cookies = {
  "nb-NO": {
    tittel: "Bruk av cookies på mineiendom.kartverket.no",
    informasjonskapsler: {
      tittel: "Informasjonskapslar (cookies)",
      avsnitt: [
        "Informasjonskapslar er mellombelse filer som blir lagra på eininga di når du besøkjer ei nettside.",
      ],
    },
    nødvendige_informasjonskapsler: {
      tittel: "Nødvendige informasjonskapsler",
      avsnitt: [
        "Når du besøker mineiendom.kartverket.no bruker vi noen få informasjonskapsler som gjør nettsiden sikker å bruke, og er nødvendige for at tjenesten skal fungere. Disse er derfor ikke mulig å velge bort.",
      ],
    },
    webanalyse: {
      tittel: "Webanalyse",
      avsnitt: [
        "Dersom du gir samtykke bruker vi noen informasjonskapsler for å samle inn statistikk om brukeraktivitet på nettsiden vår. Vi benytter verktøyet Posthog for å samle inn og analysere aktivitet for å forbedre tjenesten. Disse må du samtykke til å bruke. Funksjoanliteten på nettsiden påvirkes ikke dersom du lar være å samtykke. Tabellen med oversikt over cookies på mineiendom.kartverket.no viser hvilke informasjonskaplser som er nødvendige og hvilke som er valgfrie.",
      ],
    },
    oversikt_over_cookies_på_mineiendom: "Oversikt over cookies på mineiendom.kartverket.no",
    cookiestabell_header: ["Navn", "Formål", "Valgfrihet", "Utsteder"],
    cookiestabell: [
      {
        navn: "session_id",
        formal: "Autentisering",
        valgfrihet: "Nødvendig",
        utsteder: "Kartverket",
      },
      {
        navn: "ISTIO-STICKY",
        formal: "Infrastruktur",
        valgfrihet: "Nødvendig",
        utsteder: "Kartverket",
      },
      {
        navn: "ph_phc_{VisitorIdentifier}",
        formal: "Webanalyse",
        valgfrihet: "Valgfri",
        utsteder: "Posthog",
      },
      {
        navn: "distinct_id",
        formal: "Webanalyse",
        valgfrihet: "Valgfri",
        utsteder: "Posthog",
      },
    ],
    cookiesforklaring_tittel: "Mer informasjon om hver enkelt cookie",
    cookiesforklaring: [
      {
        tittel: "session_id",
        avsnitt: [
          "Brukes for autentisering. Nødvendig for sikker samhandlig med den felles innloggingsløsningen for offentlige tjenester, ID-porten.",
        ],
      },
      {
        tittel: "ISTIO-STICKY",
        avsnitt: [
          "Benyttes for å håndtere økten din i Kartverkets infrastruktur. Cookien er nødvendig.",
        ],
      },
      {
        tittel: "ph_phc_{VisitorIdentifier}",
        avsnitt: [
          "Identifikator vi benytter for å samle inn og analysere aktivitet for å forbedre tjenesten i verktøyet Posthog. ",
        ],
      },
      {
        tittel: "distinct_id",
        avsnitt: [
          "Identifikator vi benytter for å måle bruk av tjenesten i bruker- og analyseverktøyet Posthog.",
        ],
      },
    ],
    administrer_cookies: {
      tittel: "Administrer cookies",
      avsnitt: [
        "Du kan når som helst trekke tilbake samtykket ved å trykke “administrer cookies” under.",
      ],
    },
  },
  "nn-NO": {
    tittel: "Bruk av informasjonskapslar på mineiendom.kartverket.no",
    informasjonskapsler: {
      tittel: "Informasjonskapslar (cookies)",
      avsnitt: [
        "Informasjonskapslar er mellombelse filer som blir lagra på eininga di når du besøkjer ei nettside. Les meir om kva informasjonskapslar er på ",
      ],
    },
    nødvendige_informasjonskapsler: {
      tittel: "Nødvendige informasjonskapslar",
      avsnitt: [
        "Når du besøkjer mineiendom.kartverket.no brukar vi nokre få informasjonskapslar som gjer nettsida trygg å bruke, og som er nødvendige for at tenesta skal fungere. Desse kan derfor ikkje veljast bort.",
      ],
    },
    webanalyse: {
      tittel: "Webanalyse",
      avsnitt: [
        "Dersom du gjev samtykke, brukar vi nokre informasjonskapslar for å analysere brukaraktivitet på nettsida vår. Samtykket ditt inneber òg at vi kan samle inn data om eigedomen inkludert bygningsinformasjon og dine rettar på den. Dataen er avgrensa og vi lagrar likkje meir enn informasjonen du sjølv ser når du er logga inn. Vi nyttar verktøyet Posthog for å samle inn og analysere aktivitet for å forbetre tenesta. Funksjonaliteten på nettsida blir ikkje påverka om du let vere å samtykke. Lenger ned på denne sida vises kva for informasjonskapslar som er nødvendige, og kva for nokre som er valfrie.",
      ],
    },
    administrer_samtykke: "Administrer samtykke",
    administrer_cookies: "",
    oversikt_over_cookies_på_mineiendom: "Oversikt over cookies på mineiendom.kartverket.no",
    cookiestabell_header: ["Namn", "Føremål", "Valfridom", "Utstedar"],
    cookiestabell: [
      {
        namn: "session_id",
        formal: "Innlogging",
        valgfrihet: "Naudsynt",
        utsteder: "Kartverket",
      },
      {
        namn: "ph_phc_{VisitorIdentifier}",
        formal: "Webanalyse",
        valgfrihet: "Valfri",
        utsteder: "Posthog",
      },
      {
        namn: "distinct_id",
        formal: "Webanalyse",
        valgfrihet: "Valfri",
        utsteder: "Posthog",
      },
    ],
    cookiesforklaring_tittel: "Informasjon om kvar enkelt cookie",
    cookiesforklaring: [
      {
        tittel: "session_id",
        avsnitt: [
          "Denne informasjonskapselen blir brukt for innlogging og autentisering. Informasjonskapselen er nødvendig for at vi trygt kan bruke ID-porten som innloggingsløysing. Innhaldet i informasjonskapselen er eit tilfeldig oppretta identifikasjonsnummer og informasjon om val du har gjort. Informasjonskapselen blir sletta idet nettlesarøkta avsluttast.",
        ],
      },
      {
        tittel: "ISTIO-STICKY",
        avsnitt: [
          "Denne informasjonskapselen nyttar vi i Kartverket for å forsikre oss om at nettlesaren din ber om data frå riktig server hos Kartverket. Informasjonskapselen er nødvendig.",
        ],
      },
      {
        tittel: "ph_phc_{VisitorIdentifier}, distinct_id",
        avsnitt: [
          "Desse informasjonskapslane blir brukte for å samle inn og analysere aktivitet, som vi nyttar i arbeidet med å utvikle og forbetre tenesta. Desse blir brukte gjennom analyseverktøyet Posthog.",
        ],
      },
    ],
  },
}
