import { Box, Divider, Flex } from "@kvib/react"
import useCurrentBygning from "../../detaljert-eiendom/hooks/useCurrentBygning"
import { EgenregistreringBruksenhet } from "../types/Egenregistrering"
import { Felt, hentIkonForFelt } from "../types/schema"
import EgenregistrertVerdi from "./EgenregistrertVerdi"
import { IkonOgFeltLabel } from "./IkonOgFeltLabel"
import { MatrikkelVerdi } from "./MatrikkelVerdi"

interface EgenregistreringRadProps {
  felt: Felt
  egenregistreringForBruksenhet: EgenregistreringBruksenhet
}

const EgenregistreringRad = (props: EgenregistreringRadProps) => {
  const { dineBruksenheterIBygning } = useCurrentBygning()
  if (!dineBruksenheterIBygning) return null

  return (
    <>
      <Divider />
      <Flex columnGap={20}>
        <Box w="220px">
          <IkonOgFeltLabel felt={props.felt} icon={hentIkonForFelt(props.felt)} />
        </Box>
        <Flex>
          <MatrikkelVerdi felt={props.felt} />
          <EgenregistrertVerdi
            egenregistreringForBruksenhet={props.egenregistreringForBruksenhet}
            felt={props.felt}
          />
        </Flex>
      </Flex>
    </>
  )
}

export default EgenregistreringRad
