import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@kvib/react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import useOpprettEgenregistrering from "../../api/useOpprettEgenregistering"
import {
  AvløpSchema,
  BruksarealSchema,
  ByggeårSchema,
  EnergikilderSchema,
  Felt,
  OppvarmingSchema,
  VannforsyningSchema,
  hentIkonForFelt,
} from "../../types/schema"
import { IkonOgFeltLabel } from "../IkonOgFeltLabel"
import RegistrerAvløp from "../RegistrerAvløp"
import RegistrerByggeår from "../RegistrerByggeår"
import RegistrerEnergikilder from "../RegistrerEnergikilder"
import RegistrerOppvarming from "../RegistrerOppvarming"
import RegistrerVannforsyning from "../RegistrerVannforsyning"
import RegistrerBruksareal from "../skjemakomponenter/bruksareal/RegistrerBruksareal"

interface EgenregistreringModalProps {
  felt: Felt
  isOpen: boolean
  onClose: () => void
}

const EgenregistreringModal = ({ felt, isOpen, onClose }: EgenregistreringModalProps) => {
  const { mutate: sendEgenregistrering } = useOpprettEgenregistrering()
  const { bygningId, bruksenhetId } = useParams()

  const handleSubmit = (
    data:
      | ByggeårSchema
      | EnergikilderSchema
      | BruksarealSchema
      | OppvarmingSchema
      | AvløpSchema
      | VannforsyningSchema,
  ) => {
    sendEgenregistrering({
      bygningId: bygningId!,
      bruksenhetId: bruksenhetId!,
      key: felt,
      egenregistrering: data,
    })
    onClose()
  }

  const getFieldContent = (field: Felt) => {
    switch (field) {
      case "byggeår":
        return <RegistrerByggeår handleSubmit={handleSubmit} />
      case "bruksareal":
        return <RegistrerBruksareal handleSubmit={handleSubmit} />
      case "energikilder":
        return <RegistrerEnergikilder handleSubmit={handleSubmit} />
      case "oppvarming":
        return <RegistrerOppvarming handleSubmit={handleSubmit} />
      case "vannforsyning":
        return <RegistrerVannforsyning handleSubmit={handleSubmit} />
      case "avløp":
        return <RegistrerAvløp handleSubmit={handleSubmit} />
      default:
        return null
    }
  }

  const { t } = useTranslation("nøkkelbegreper")

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose()
      }}
      closeOnOverlayClick={false}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent p="16px">
        <ModalHeader>
          <IkonOgFeltLabel felt={felt} icon={hentIkonForFelt(felt)} />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>{getFieldContent(felt)}</ModalBody>
        <ModalFooter gap={4}>
          <Button type="submit" form={`${felt}-form`} colorScheme="green">
            Send inn
          </Button>
          <Button
            variant="secondary"
            colorScheme="blue"
            onClick={() => {
              onClose()
            }}
          >
            {t("avbryt")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default EgenregistreringModal
