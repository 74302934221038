const keys = {
  "nb-NO": {
    logg_ut: "Logg ut",
    meny: "Meny",
    hjelp: "Hjelp og støtte",
    innlogget: "Innlogget",
    logget_inn_arialabel: "Logget inn",
    navn_placeholder: "Placeholder på navn",
    hopp_til_hovedinnhold: "Hopp til hovedinnhold",
  },
  "nn-NO": {
    logg_ut: "Logg ut",
    meny: "Meny",
    hjelp: "Hjelp og støtte",
    innlogget: "Innlogget",
    logget_inn_arialabel: "Logget inn",
    navn_placeholder: "Placeholder på navn",
    hopp_til_hovedinnhold: "",
  },
}

type NavbarKey = keyof (typeof keys)["nb-NO"]

type Navbar = {
  [K in keyof typeof keys]: Record<NavbarKey, string>
}

export const navbar: Navbar = {
  "nb-NO": keys["nb-NO"],
  "nn-NO": keys["nn-NO"],
}
