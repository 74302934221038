import { apiRoute } from "../../../../environments"
import { ApiError } from "../../../core/error-handling/ApiError"
import { BortfestetDetaljertEiendom, DetaljertEiendomResponse } from "../types/DetaljertEiendom"

export const eiendomRouter = {
  hentEiendom: async (matrikkelenhetId: string) => {
    const res = await fetch(`${apiRoute}/eiendommer/${matrikkelenhetId}`)

    if (!res.ok) {
      const body = await res.json()
      throw new ApiError(body)
    }

    const detaljertEiendom: DetaljertEiendomResponse = await res.json()
    return detaljertEiendom
  },
}

export function erBortfestet(data: DetaljertEiendomResponse): data is BortfestetDetaljertEiendom {
  return data.type === "bortfestet"
}
