import { TextProps } from "@kvib/react"
import { Brødtekst } from "../../../core/text/components/Brødtekst"
import { useFontStyles } from "../../../core/text/fontStyles"

interface Props extends TextProps {
  fieldValue?: string
  className?: string
  id?: string
  ariaLabel?: string
}

const FeltverdiForEiendomsdetaljer = ({ fieldValue, id, ariaLabel, className }: Props) => {
  const { getFontSize } = useFontStyles()
  return (
    <Brødtekst
      className={className}
      fontWeight="bold"
      fontSize={getFontSize("h3")}
      id={id}
      aria-label={ariaLabel}
      height="var(--kvib-sizes-10)"
      alignContent="center"
    >
      {fieldValue}
    </Brødtekst>
  )
}

export default FeltverdiForEiendomsdetaljer
