import { Flex } from "@kvib/react"
import { useTranslation } from "react-i18next"
import { useEiendom } from "../../api/useEiendom"
import useCurrentBygning from "../../hooks/useCurrentBygning"
import { AccordionInneIBygningskort } from "./AccordionInneIBygningskort"
import EgenskaperVedBygning from "./EgenskaperVedBygning"
import NøkkelinfoOmAlleBruksenheterIBygning from "./NøkkelinfoOmAlleBruksenheterIBygning"
import OmBygningen from "./OmBygningen"

export const BygningskortInnhold = ({ bygningsId }: { bygningsId: string }) => {
  const { currentBygning, dineBruksenheterIBygning } = useCurrentBygning(bygningsId)
  const { data: eiendom } = useEiendom()
  const { t } = useTranslation(["bygningskort", "bruksenhetskort"])

  if (!eiendom || !currentBygning || !dineBruksenheterIBygning) return null

  const erEierseksjon = eiendom.matrikkelenhetstype === "Eierseksjon"

  const componentsOrder = erEierseksjon
    ? ["bruksenheter", "omBygningen", "egenskaperVedBygningen"]
    : ["omBygningen", "egenskaperVedBygningen", "bruksenheter"]

  return (
    <Flex
      flexDir="column"
      columnGap={{ base: "16px", md: "48px", lg: "24px" }}
      flexWrap={{ base: "wrap", xl: "nowrap" }}
      justifyContent="space-between"
      m="16px"
    >
      {componentsOrder.map((component, index) => {
        switch (component) {
          case "bruksenheter":
            return (
              <AccordionInneIBygningskort
                key={index}
                åpenFraStart={erEierseksjon}
                accordionTittel={t("dine_bruksenheter_i_bygningen_accordion_overskrift", {
                  count: dineBruksenheterIBygning.length,
                  ns: "bruksenhetskort",
                })}
              >
                <NøkkelinfoOmAlleBruksenheterIBygning byggId={currentBygning.byggId} />
              </AccordionInneIBygningskort>
            )
          case "omBygningen":
            return (
              <AccordionInneIBygningskort
                key={index}
                accordionTittel={t("bygningskort_overskrift")}
                åpenFraStart={!erEierseksjon}
              >
                <OmBygningen bygning={currentBygning} />
              </AccordionInneIBygningskort>
            )
          case "egenskaperVedBygningen":
            return (
              <AccordionInneIBygningskort
                key={index}
                accordionTittel={t("egenskaper_ved_bygningen_accordion_overskrift")}
              >
                <EgenskaperVedBygning bygning={currentBygning} />
              </AccordionInneIBygningskort>
            )
          default:
            return null
        }
      })}
    </Flex>
  )
}
