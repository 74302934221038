import { useQuery } from "@tanstack/react-query"
import { useParams } from "react-router"
import { api } from "../../../shared-api/router"

const useEgenregistreringBruksenhet = () => {
  const { bygningId, bruksenhetId } = useParams()

  const egenregistreringsQuery = useQuery({
    queryKey: ["egenregistrering-bruksenhet", bygningId, bruksenhetId],
    queryFn: () => api.egenregistrering.hentEgenregistreringBruksenhet(bygningId!, bruksenhetId!),
    throwOnError: true,
  })

  return egenregistreringsQuery
}

export default useEgenregistreringBruksenhet
