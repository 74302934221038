import { Box, Flex } from "@kvib/react"
import { useTranslation } from "react-i18next"
import DetaljPopover from "../../components/DetaljPopover"
import FeltverdiForEiendomsdetaljer from "./FeltverdiForEiendomsdetaljer"
import OverskriftForEiendomsdetaljer from "./OverskriftForEiendomsdetaljer"

const BortfestetDetalj = () => {
  const { t } = useTranslation("detaljertInnsyn")
  return (
    <DetaljPopover
      forklaring={t("bortfestet_eiendom_forklaring", "detaljertInnsyn")}
      buttonAriaLabel=""
    >
      <OverskriftForEiendomsdetaljer>Er festet bort</OverskriftForEiendomsdetaljer>

      <Box as={Flex} alignItems="center">
        <FeltverdiForEiendomsdetaljer fieldValue="Ja" />
      </Box>
    </DetaljPopover>
  )
}

export default BortfestetDetalj
