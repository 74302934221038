import { Alert, Button, Flex, Image } from "@kvib/react"
import { useNavigate } from "react-router"
import manglerEiendommerIllustrasjon from "../../../assets/questionmark_man.svg"
import useHandleLogout from "../../auth/hooks/useHandleLogout"
import InfotekstHvorforDukkerIkkeEiendommenMinOpp from "../../infosider/hjelp-og-stotte/InfotekstHvorforDukkerIkkeEiendommenMinOpp"
import { useScreenView } from "../hooks/useScreenview"
import { FinnerIkkeEiendommerErrorBox } from "./FinnerIkkeEiendommerInfoboks"
import { ErrorType } from "./types/ErrorType"

interface Props {
  errorType: Extract<ErrorType, "FANT_IKKE_PERSON" | "FANT_INGEN_EIENDOMMER">
  errorTitle: string
}

const FinnerIkkeEiendommer = (props: Props) => {
  const brukerIkkeFunnet = !!(props.errorType === "FANT_IKKE_PERSON")
  const navigate = useNavigate()
  const { isDesktop } = useScreenView()
  const { mutate: handleLogout } = useHandleLogout()

  return (
    <>
      <Alert bg="white" variant="left-accent" status="info">
        {props.errorTitle}
      </Alert>
      <FinnerIkkeEiendommerErrorBox tittel="Hvorfor ser jeg ingen eiendommer?">
        <Flex flexDir={isDesktop ? "row" : "column"}>
          <InfotekstHvorforDukkerIkkeEiendommenMinOpp skalViseFnrSomÅrsak={brukerIkkeFunnet} />
          {isDesktop && (
            <Image
              src={manglerEiendommerIllustrasjon}
              p="12px"
              marginRight="auto"
              aria-hidden="true"
            />
          )}
        </Flex>
        {brukerIkkeFunnet ? (
          <Button w="fit-content" leftIcon="logout" onClick={() => handleLogout()} iconAriaIsHidden>
            Logg ut av ID-porten
          </Button>
        ) : (
          <Button
            rightIcon="arrow_forward"
            onClick={() => navigate("/hjelp")}
            iconAriaIsHidden
            w="fit-content"
            mt="24px"
          >
            Les mer om utvalget
          </Button>
        )}
      </FinnerIkkeEiendommerErrorBox>
    </>
  )
}

export default FinnerIkkeEiendommer
